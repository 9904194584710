import React from 'react';
import { Container, PageSection, Image } from '@dovera/design-system';

export default () => (
  <PageSection
    className="d-flex align-items-middle"
    style={{ height: '100vh' }}
  >
    <Container>
      <div className="text-center">
        <Image
          src={`${process.env.PUBLIC_URL}/assets/logo-moja-dovera.svg`}
          alt={'dovera'}
          width="large"
          className="no-mrg-bottom"
        />
      </div>
    </Container>
  </PageSection>
);
