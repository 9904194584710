import { Loadable, Nullable } from './index.d';
import { SignType, Employer, Address } from './common.d';
import { RegistrationRecapResponse } from './regRecap.d';

export interface RegistrationReducer {
  isLoading: boolean;
  wizardInProgress: boolean;
  isSaved: boolean;
  isFinished: boolean;
  isDownloaded: boolean;
  error: any;
  registration: RegistrationObject;
  registrationRecap: RegistrationRecapObject & Loadable;
  signType: Nullable<SignType>;
  content: RegistrationResponse;
  verification: VerifyInsureeRespone;
  smsLink: SMSLinkRespone;
}

export interface VerifyInsureeRespone extends Loadable {
  result?: Nullable<string>;
  statusCode?: Nullable<'S' | 'W' | 'E'>;
}

export interface SMSLinkRespone extends Loadable {
  sended: Nullable<string>;
  message: Nullable<string>;
}

export interface RegistrationResponse {
  insureeRegistrationID: Nullable<string>;
  pdfName: Nullable<string>;
  pdfContent: Nullable<string>;
  token: Nullable<string>;
  toSign: boolean;
  documentSignatusId: Nullable<string | boolean>;
}

export interface RegistrationObject {
  id: Nullable<number>;
  idProspekt: Nullable<number>;
  poistenec: Nullable<Insuree>;
  zamestnavatelUdaje: Nullable<Employer | {}>;
  podavatelUdaje: Nullable<Insuree | {}>;
  kodTypPrihlasky: Nullable<RegTypeCode | {}>;
  datumCasPodania: Nullable<string | Date>;
  datumCasPrijatiaPrihlasky: Nullable<string | Date>;
  datumVznikuSkutocnostiPreVznikPoistenia: Nullable<string | Date>;
  datumVznikuPoistenia: Nullable<string | Date>;
  kodPlatitelaPoistneho: Nullable<string>;
  prihlaskaDovod: Nullable<string>;
  kodInaZdravotnaPoistovna: Nullable<string>;
  kodPredchadzajucaZP: Nullable<string>;
  kodDruhDochodku: Nullable<string>;
  kodTypNahradyPrijmu: Nullable<string>;
  idMakler: Nullable<string>;
  datumCasZaevidovania: Nullable<Date>;
  kodOznaceniePoistenca: Nullable<string>;
  kodUrceniePlatitelaPoistneho: Nullable<string>;
  kodTypPribuzenskehoVztahu: Nullable<string>;
  kodTypKategorie: Nullable<string>;
  matkaPoistenaVZP?: Nullable<boolean>;
  matkaPoistovna: Nullable<string>;
  sposobPrevzatiaNarPreukazu: Nullable<string>;
  sposobDoruceniaNaPodpis: Nullable<number>;
  kodPobockaPrevz: Nullable<number>;
  kodTypPodnikania: Nullable<string>;
  kodSkupinaKategorie: Nullable<string>;
  icoSZCO: Nullable<string>;
  nazovSZCO: Nullable<string>;
  suhlasSoSpracovanimOsobnychUdajov: Nullable<boolean>;
  NamietkaKontaktneUdaje: Nullable<boolean>;
  suhlasSPoskytnutimUdajovExterne: Nullable<boolean>;
  dorucenieKurierom: Nullable<CourierAddress | {}>;
  dataVytazeneOCR: boolean;
  mobilneCisloPreSMS: string | null;
  prilohy: Nullable<RegistrationDocumentDto[]>;
  identifikacnyDoklad: Nullable<{ predna: string; zadna: string }>;
}

export interface RegistrationRecapObject {
  newInsureeDataForSign: Nullable<RegistrationRecapResponse>;
  errorMessage: Nullable<string>;
  approval: RecapApproval;
  employeerInternalNumber: Nullable<string>;
  registrationType: Nullable<string>;
  personBirthNumber: Nullable<string>;
  uploadedDocuments: any[];
  appendingRegDocuments: boolean;
}

interface RecapApproval {
  isLoading: boolean;
  result: boolean | null;
}

export enum RegTypeCode {
  novorodenec = 'N',
  zmena = 'Z',
  ostatne = 'O',
}

export interface Insuree {
  id?: string;
  rodneCislo?: string;
  datumNarodenia?: string;
  pohlavie?: string;
  titul?: string;
  meno?: string;
  priezvisko?: string;
  titulZa?: string;
  rodnePriezvisko?: string;
  statnaPrislusnostSkratka?: string;
  typPobytuVSROd?: string;
  poznamkaKPobytuVSR?: string;
  datumPobytuVSROd?: string;
  datumPobytuVSRDo?: string;
  cisloDokladu?: string;
  kodTypZakonnehoZastupcu?: string;
  adresaTrvaleBydlisko?: Address;
  adresaPrechodneBydlisko?: Address;
  adresaKontaktna?: Address;
  kontaktneUdaje?: Contact[];
}

interface Requester {
  id: string;
  rodneCislo: string;
  datumNarodenia: Date;
  pohlavie: string;
  titul: string;
  meno: string;
  priezvisko: string;
  titulZa: string;
  rodnePriezvisko: string;
  statnaPrislusnostSkratka: string;
  typPobytuVSROd: string;
  poznamkaKPobytuVSR: string;
  datumPobytuVSROd: Date;
  datumPobytuVSRDo: Date;
  cisloDokladu: string;
  kodTypZakonnehoZastupcu: string;
  adresaTrvaleBydlisko: Address;
  adresaPrechodneBydlisko: Address;
  adresaKontaktna: Address;
  kontaktneUdaje: Contact;
}

export interface Contact {
  id: Nullable<string>;
  typ: string;
  kontakt: string;
}

export interface CourierAddress {
  menoPrijimatela: string;
  priezviskoPrijimatela: string;
  telefonickyKontakt: string;
  adresaDorucenia: Address;
}

export enum TypDokumentuKod {
  op_predna_strana = 'OP1',
  op_zadna_strana = 'OP2',
  neviem = 'UNDF',
}

export interface DokumentDto {
  Id: Nullable<string>;
  NazovSuboru: Nullable<string>;
  Mime?: Nullable<string>;
  Obsah?: Nullable<string>;
  JeKomprimovany?: boolean;
  Popis?: Nullable<string>;
}

export interface RegistrationDocumentDto {
  nazovSuboru?: Nullable<string>;
  mime?: Nullable<string>;
  obsah?: Nullable<string>;
  typDokumentu?: Nullable<TypDokumentuKod>;
}

export interface VerificationDataInput {
  token: string;
  birthNumber: string;
}

export interface ApprovalDataInput {
  token: string;
  birthNumber: string;
  approval: boolean;
}

export interface ReferralDataInput {
  mobile: string;
  employeerInternalNumber: string;
}
export interface RegDocumentsDataInput {
  token: string;
  documents: RegistrationDocumentDto[];
}
