import { setOCRStatus } from '../actions/wizard-action';
import { PersonData } from '../types/wizard.d';
import { slovakStateName, slovakStateCode } from '../constants/index';
import { SexType, InsureeType, OcrStatus } from '../types/common.d';
import { temporaryStoreOcrData, emptyOcrData } from '../actions/ocr-actions';
import moment from 'moment';
import formats from '../constants/formats';

const OCR_API_CALL = 'OCR_CAPTURED_IMAGE_SUCCESS';

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

const callApiRequest = (route: string, query: any): Promise<Response> =>
  fetch(`${window.env.config.apiPath}/AutoComplete/${route}`, {
    headers: { 'Content-Type': 'application/json-patch+json' },
    method: 'POST',
    body: JSON.stringify(query),
  }).then(handleErrors);

export const ocrMiddleware = store => next => action => {
  if (action.type === OCR_API_CALL) {
    const wizardStepOCR = action.step;
    const wizardInsureeType = action.insureeType;
    const resData =
      action.response.result && JSON.parse(action.response.result);

    if (
      !resData ||
      (resData.nationality &&
        resData.nationality.trim().replace(/\s/g, '') !== 'SVK')
    ) {
      store.dispatch(emptyOcrData(wizardStepOCR, wizardInsureeType));
      store.dispatch(setOCRStatus(wizardInsureeType, OcrStatus.failure));
      return next(action);
    }

    //  spraed copy not working
    const personData: PersonData = JSON.parse(
      JSON.stringify(
        store.getState().wizard.step2[wizardInsureeType || InsureeType.insuree],
      ),
    );
    personData.firstName = resData.givenNames.trim() || null;
    personData.lastName = resData.surname.trim() || null;
    personData.birthName = resData.surnameAtBirth.trim() || null;
    personData.birthPlace = resData.placeOfBirth.trim() || null;
    personData.sex = resData.sex === 'F' ? SexType.female : SexType.male;
    personData.nationality = slovakStateCode;
    personData.idNumber = resData.number.trim() || null;
    personData.dateOfBirth =
      resData.dateOfBirth &&
      moment(resData.dateOfBirth.trim(), formats.DATE_ONLY)
        .set({ hour: 12, minute: 0, second: 0 })
        .utcOffset(1)
        .format('YYYY-MM-DD');
    personData.birthNumber =
      resData.personalNumber &&
      resData.personalNumber.trim().replace(/\//g, '');
    personData.academicDegreeBeforeName =
      resData.specialRemarks && resData.specialRemarks.join(' ').trim();

    // ocr
    personData.ocr.firstName = !!resData.givenNames;
    personData.ocr.lastName = !!resData.surname;
    personData.ocr.birthName = !!resData.surnameAtBirth;
    personData.ocr.birthPlace = !!resData.placeOfBirth;
    personData.ocr.nationality = !!resData.nationality;
    personData.ocr.idNumber = !!resData.number;
    personData.ocr.dateOfBirth = !!resData.dateOfBirth;
    personData.ocr.birthNumber = !!resData.personalNumber;
    personData.ocr.academicDegreeBeforeName = resData.specialRemarks.length > 0;

    if (resData.address && resData.address.length === 0) {
      store.dispatch(
        temporaryStoreOcrData(wizardStepOCR, personData, wizardInsureeType),
      );
    } else {
      let houseNumber = null;
      let town = null;
      let street = null;
      const [ocrAddress0, ocrAddress1] = resData.address;
      //  only 1 part of address - street + houseNumber
      if (!ocrAddress1) {
        const ap = ocrAddress0.split(' ');
        houseNumber = ap.pop().trim();
        town = ap.join(' ').trim();
        //  @ts-ignore
        // } else if (isNaN(parseInt(ocrAddress0, 10))) {
      } else if (/\d/.test(ocrAddress0)) {
        //  address has 2 parts towna & street + houseNumber

        //  detect if first part is town or street + houseNumber
        //  first part is street + houseNumber

        town = ocrAddress1;
        const ap = ocrAddress0.split(' ');
        houseNumber = ap.pop().trim();
        street = ap.join(' ').trim();
      } else {
        town = ocrAddress0;
        const ap = ocrAddress1.split(' ');
        houseNumber = ap.pop().trim();
        street = ap.join(' ').trim();
      }

      callApiRequest('GetCities', { search: town, country: slovakStateCode })
        .then((resTown: any) => {
          // @ts-ignore
          personData.permanentAddress.statKod = slovakStateCode;
          // @ts-ignore
          personData.permanentAddress.statNazov = slovakStateName;

          // @ts-ignore
          personData.permanentAddress.cislo = houseNumber;

          //  ocr
          personData.ocr.statNazov = true;
          personData.ocr.cislo = !!houseNumber;

          if (resTown && resTown.result && resTown.result.length) {
            // @ts-ignore
            personData.permanentAddress.obecKod = resTown.result[0].code;
            // @ts-ignore
            personData.permanentAddress.obecNazov = resTown.result[0].name;
            // @ts-ignore
            personData.permanentAddress.obecText = resTown.result[0].name;
            // @ts-ignore
            personData.permanentAddress.region = resTown.result[0].region;
            personData.ocr.obecNazov = !!resTown.result[0].name;

            if (!street) {
              callApiRequest('GetPostCodes', {
                cityCode: resTown.result[0].code,
                country: slovakStateCode,
              })
                .then((resZIP: any) => {
                  // @ts-ignore
                  personData.permanentAddress.psc = resZIP.result[0].code;
                  personData.ocr.psc = !!resZIP.result[0].code;
                  store.dispatch(
                    temporaryStoreOcrData(
                      wizardStepOCR,
                      personData,
                      wizardInsureeType,
                    ),
                  );

                  store.dispatch(
                    setOCRStatus(wizardInsureeType, OcrStatus.success),
                  );
                  return next(action);
                })
                .catch(() => {
                  store.dispatch(
                    temporaryStoreOcrData(
                      wizardStepOCR,
                      personData,
                      wizardInsureeType,
                    ),
                  );
                  store.dispatch(
                    setOCRStatus(wizardInsureeType, OcrStatus.success),
                  );
                  return [];
                });
            } else if (street) {
              callApiRequest('GetStreets', {
                search: street,
                cityCode: resTown.result[0].code,
              })
                .then((resStreet: any) => {
                  // @ts-ignore
                  personData.permanentAddress.ulicaKod =
                    resStreet.result[0].code;
                  // @ts-ignore
                  personData.permanentAddress.ulicaNazov =
                    resStreet.result[0].name;

                  // ocr

                  personData.ocr.ulicaNazov = !!resStreet.result[0].streetName;
                  if (
                    resStreet &&
                    resStreet.result &&
                    resStreet.result.length
                  ) {
                    callApiRequest('GetPostCodes', {
                      cityCode: resTown.result[0].code,
                      street: resStreet.result[0].code,
                      country: slovakStateCode,
                    })
                      .then((resFinal: any) => {
                        // @ts-ignore
                        personData.permanentAddress.statKod = slovakStateCode;
                        // @ts-ignore
                        personData.permanentAddress.statNazov = slovakStateName;

                        // @ts-ignore
                        personData.permanentAddress.obecKod =
                          resFinal.result[0].cityCode;
                        // @ts-ignore
                        personData.permanentAddress.obecNazov =
                          resFinal.result[0].cityName;
                        // @ts-ignore
                        personData.permanentAddress.obecText =
                          resFinal.result[0].cityName;

                        // @ts-ignore
                        personData.permanentAddress.ulicaKod =
                          resFinal.result[0].streetCode;
                        // @ts-ignore
                        personData.permanentAddress.ulicaNazov =
                          resFinal.result[0].streetName;

                        // @ts-ignore
                        personData.permanentAddress.cislo = houseNumber;
                        // @ts-ignore
                        personData.permanentAddress.psc =
                          resFinal.result[0].code;

                        //  ocr
                        personData.ocr.statNazov = true;
                        personData.ocr.obecNazov = !!resFinal.result[0]
                          .cityName;
                        personData.ocr.ulicaNazov = !!resFinal.result[0]
                          .streetName;
                        personData.ocr.cislo = !!houseNumber;
                        personData.ocr.psc = !!resFinal.result[0].code;

                        store.dispatch(
                          temporaryStoreOcrData(
                            wizardStepOCR,
                            personData,
                            wizardInsureeType,
                          ),
                        );
                        store.dispatch(
                          setOCRStatus(wizardInsureeType, OcrStatus.success),
                        );
                        return next(action);
                      })
                      .catch(error => {
                        store.dispatch(
                          temporaryStoreOcrData(
                            wizardStepOCR,
                            personData,
                            wizardInsureeType,
                          ),
                        );
                        store.dispatch(
                          setOCRStatus(wizardInsureeType, OcrStatus.success),
                        );
                        return [];
                      });
                  } else {
                    store.dispatch(
                      temporaryStoreOcrData(
                        wizardStepOCR,
                        personData,
                        wizardInsureeType,
                      ),
                    );

                    store.dispatch(
                      setOCRStatus(wizardInsureeType, OcrStatus.success),
                    );
                  }
                })
                .catch(error => {
                  store.dispatch(
                    temporaryStoreOcrData(
                      wizardStepOCR,
                      personData,
                      wizardInsureeType,
                    ),
                  );
                  store.dispatch(
                    setOCRStatus(wizardInsureeType, OcrStatus.success),
                  );
                  return [];
                });
            } else {
              store.dispatch(
                temporaryStoreOcrData(
                  wizardStepOCR,
                  personData,
                  wizardInsureeType,
                ),
              );

              store.dispatch(
                setOCRStatus(wizardInsureeType, OcrStatus.success),
              );
            }
          } else {
            store.dispatch(
              temporaryStoreOcrData(
                wizardStepOCR,
                personData,
                wizardInsureeType,
              ),
            );

            store.dispatch(setOCRStatus(wizardInsureeType, OcrStatus.success));
          }
        })
        .catch(() => {
          store.dispatch(
            temporaryStoreOcrData(wizardStepOCR, personData, wizardInsureeType),
          );
          store.dispatch(setOCRStatus(wizardInsureeType, OcrStatus.success));
          return [];
        });
    }

    if (resData.placeOfBirth) {
      const birthPlace = resData.placeOfBirth;
      callApiRequest('GetCities', {
        search: birthPlace,
        country: slovakStateCode,
      })
        .then((resPlace: any) => {
          if (resPlace && resPlace.result && resPlace.result.length) {
            // @ts-ignore
            personData.birthPlace = resPlace.result[0].name;
            // ocr
            personData.ocr.birthPlace = !!resPlace.result[0].name;
          } else {
            store.dispatch(
              temporaryStoreOcrData(
                wizardStepOCR,
                personData,
                wizardInsureeType,
              ),
            );

            store.dispatch(setOCRStatus(wizardInsureeType, OcrStatus.success));
          }
        })
        .catch(() => {
          store.dispatch(
            temporaryStoreOcrData(wizardStepOCR, personData, wizardInsureeType),
          );
          store.dispatch(setOCRStatus(wizardInsureeType, OcrStatus.success));
          return [];
        });
    }
    return next(action);
  }

  return next(action);
};
