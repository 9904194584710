import moment from 'moment';

const priloha2Pdf = 'prilohac2_zoznam_obci.pdf';
const priloha3Pdf = 'prilohac3_zoznam_podriadenych_zamestnancov.pdf';
const priloha4Pdf = 'prilohac4_manual_pre_zamestnancov.pdf';
const year = moment().format('YYYY');

export default {
  title: 'Vzdelávanie',
  contract: {
    title: `Dohoda o vykonaní práce ${year} - prílohy`,
    list: [
      {
        title: `Príloha č. 2 k dohode o vykonaní práce ${year} – Zoznam obcí`,
        href: `${process.env.PUBLIC_URL}/assets/${priloha2Pdf}`,
      },
      {
        title:
          'Príloha č. 3 k dohode o vykonaní práce – Zoznam podriadených zamestnancov',
        href: `${process.env.PUBLIC_URL}/assets/${priloha3Pdf}`,
      },
      {
        title:
          'Príloha č.4 k dohode o vykonaní práce – Manuál pre zamestnancov vykonávajúcich získavanie poistencov',
        href: `${process.env.PUBLIC_URL}/assets/${priloha4Pdf}`,
      },
    ],
  },
  demo: {
    title: 'Demo',
    linkTitle: 'Demo aplikácie',
  },
  viaVideo: {
    title: 'Via video',
    href: 'https://youtu.be/giV93sQ5iYs',
  },
  magazine: {
    title: 'Časopis',
    linkTitle: 'Časopis Dôvera',
  },
  articles: {
    title: 'Aktuality z Dôvery',
    list: [
      {
        title: 'Ako dosiahnuť dobrú krv? Poradíme vám',
        href:
          'https://www.dovera.sk/aktuality/5831-ako-dosiahnut-dobru-krv-poradime-vam',
      },
      {
        title:
          'Ak máte psa, poznáte to. Teraz pomáhajú znižovať úzkosť a napätie aj pacientom',
        href:
          'https://www.dovera.sk/aktuality/5811-ak-mate-psa-poznate-to-teraz-pomahaju-znizovat-uzkost-a-napatie-aj-pacientom',
      },
      {
        title: 'Viete, ako a prečo zmizli papierové recepty?',
        href:
          'https://www.dovera.sk/aktuality/5785-viete-ako-a-preco-zmizli-papierove-recepty',
      },
    ],
  },
};
