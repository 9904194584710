import { color } from '@dovera/design-system';

const palette = {
  primary: {
    100: '#D0F2E7',
    400: '#42CCA1',
    500: '#13BF89',
    600: '#0F996E',
    700: '#0B7352',
  },
  secondary: {
    100: '#FEF2E1',
    400: '#FBB249',
    500: '#F89B20',
    600: '#C87F16',
  },
  grey: {
    50: '#F4F3F3',
    100: '#E9E8E7',
    200: '#DDDCDB',
    300: '#B1AEAA',
    500: '#8F8B86',
  },
};

const colors = {
  textPrimary: palette.primary[500],
  textSecondary: palette.secondary[500],
  textGrey: palette.grey[500],
  addressNodifGrey: palette.grey[50],
  textDisabled: '#A7A9AC',
  textError: '#FF0000',
  tableAlt: '#F2F5FA',
  ocrTextField: '#E8EEF6',
  primary: palette.primary[500],
  secondary: palette.secondary[500],
  stepper: {
    outline: 'rgba(31, 87, 165, 0.3)',
  },
  divider: '#E5E5E5',
  warning: '#FF9800',
  palette,
  white: '#fff',
};

export { colors, color };
