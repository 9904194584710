export default {
  required: 'Povinná položka.',
  required_birthNumber: 'Zadajte rodné číslo.',
  required_firstName: 'Zadajte meno.',
  required_lastName: 'Zadajte priezvisko.',
  required_birthName: 'Zadajte rodné priezvisko.',
  required_dateOfBirth: 'Zadajte dátum narodenia.',
  required_idNumber: 'Zadajte číslo občianskeho preukazu.',
  required_nationality: 'Zadajte štátnu príslušnosť.',
  required_statNazov: 'Zadajte štát.',
  required_obecNazov: 'Zadajte mesto.',
  required_psc: 'Zadajte PSČ.',
  required_cislo: 'Zadajte súpisné/orientačné číslo.',
  required_payer: 'Vyberte, kto za vás platí poistné.',
  required_payer_ico: 'Zadajte IČO.',
  required_payer_employer: 'Zadajte názov zamestnávateľa.',
  required_phone: 'Zadajte mobil.',
  required_employer_city: 'Zadajte mesto.',
  required_employer_city_number: 'Zadajte číslo.',
  required_employer_psc: 'Zadajte PSČ.',
  required_verify_code: 'Zadajte Identifikačný kód.',
  required_birthPlace: 'Zadajte miesto narodenia.',
  required_maritalStatus: 'Zadajte rodinný stav.',
  required_phoneNumber: 'Zadajte telefónne číslo.',
  required_emailAddress: 'Zadajte e-mailovú adresu.',
  required_iban: 'Zadajte IBAN.',
  required_supervisor: 'Zadajte nadriadeného.',
  required_nationality_pick: 'Vyberte štátnu príslušnosť.',
  required_message_text: 'Zadajte text správy.',
  required_person_radio_group:
    'Pre pokračovanie, prosím, vyberte jednu z možností.',
  seletedIssue: 'Prosím, vyberte jednu z ponúkaných možností.',
  rc: 'Rodné číslo nie je správne, zadajte ho znovu.',
  rcFormat: 'Rodné číslo je potrebné vyplniť bez lomítka.',
  rcOnlyNumbers: 'Rodné číslo može obsahovať len čísla.',
  bicOnlyNumbers: 'BIČ može obsahovať len čísla.',
  bic: 'BIČ nie je správne, zadajte ho znovu.',
  personIsNotChild: 'Poistenec nie je dieťa.',
  personIsNotNewBorn: 'Prosím, zadajte rodné číslo novorodenec.',
  personIsNewBorn: 'Prosím, zadajte rodné číslo osoby staršej ako 60 dní.',
  personIsNotAdult: 'Prosím, zadajte rodné číslo dospelej osoby.',
  forbiddenChars: 'Nepovolené znaky',
  datesNotSame: 'Rodné číslo a dátum narodenia nie je rovnaký dátum',
  phone: 'Nesprávny formát telefónneho čísla',
  email: 'Nesprávny formát emailu',
  ico: 'Nesprávny formát IČO.',
  ibanFormat: 'Nesprávny formát. Zadajte platný slovenský IBAN.',
  invalidFormatVerifyCode: 'Nesprávny formát identifikačného kódu',
  invalidLengthVerifyCode: 'Zadajte 6 miestny identifikačný kód',
  invalidVerifyCode: 'Zadali ste nesprávny kód',
  invalidContact: 'Tento kontakt nie je možné použiť.',
  requiredPhone:
    'Na to, aby k vám mohol prísť kuriér s prihláškou potrebujeme vaše telefónne číslo',
  idNumber: 'Nesprávne číslo dokladu',
  file: {
    invalidType: 'Nesprávny formát súboru. Povolené formáty:',
    tooLarge: 'Súbor je príliš veľký. Maximálna veľkost súboru:',
    tooSmall: 'Súbor je príliš malý. Mninimálna veľkost súboru:',
    manyFiles: 'Príliš veľa súbor.',
  },
  login:
    'Zadané prihlasovacie meno je v nesprávnom formáte. Prihlasovacie meno musí obsahovať minimálne 6 a maximálne 15 alfanumerických znakov bez diakritiky. Môže obsahovať bodku, podtržník, pomlčku a @.',
  capsLock: 'Ak chcete použiť malé písmená, vypnite si Caps Lock.',
  passwordsNotSame: 'Hesla nie sú rovnaké',
  passwordBadFormat: 'Nesprávny formát hesla.',
  passwordSameAsOld:
    'Nové heslo je rovnaké ako pôvodné heslo. Prosím, zvoľte iné heslo.',
  wrongImageFormat: 'Súbor musí byť vo formáte .jpg, .jpeg alebo .png',
  wrongAttachmentFormat: value => `Súbor musí byť vo formáte ${value}`,
  wrongAttachmentSize: value => `Súbor musí byť menší ako ${value}`,
  wrongFormat: (value?: string) =>
    value
      ? `Zadali ste ${value} v nepodporovanom formáte`
      : 'Zadali ste nesprávny formát',
  unknown: 'Nastala neočakávaná chyba',
  ocr: {
    academicDegree: 'Prosím, vyberte jednu z ponúkaných možností.',
  },
};
