import {
  VALIDATE_BIRTH_NUMBER,
  VALIDATE_CONTRACT_BIRTH_NUMBER,
  VALIDATE_EMPLOYEE_NUMBER,
  VALIDATE_INSUREE_RELATION,
} from '../api/actionSets';
import { ValidationReducer } from '../types/reducers.d';
import {
  RESET_AFTER_CHANGE_INSURED_PERSON,
  RESET_REGISTRATION,
  RESET_WITH_ZZ,
} from '../actions/registration-action';

const defaultState: ValidationReducer = {
  employee: {
    isLoading: false,
    isValid: null,
    error: null,
  },
  insuree: {
    isLoading: false,
    isValid: null,
    error: null,
  },
  legalRepresentative: {
    isLoading: false,
    isValid: null,
    error: null,
  },
  mother: {
    isLoading: false,
    isValid: null,
    error: null,
  },
  contract: {
    isLoading: false,
    isValid: null,
    error: null,
  },
};

export default (state: ValidationReducer = defaultState, action) => {
  switch (action.type) {
    case VALIDATE_BIRTH_NUMBER.LOADING:
      return {
        ...state,
        [action.insureeType]: {
          ...state[action.insureeType],
          isLoading: true,
          error: null,
          isValid: null,
        },
      };
    case VALIDATE_BIRTH_NUMBER.SUCCESS:
      return {
        ...state,
        [action.insureeType]: {
          ...state[action.insureeType],
          isLoading: false,
          error: action.response.errorMessage,
          isValid: action.response.isValid,
        },
      };
    case VALIDATE_BIRTH_NUMBER.ERROR:
      return {
        ...state,
        [action.insureeType]: {
          ...state[action.insureeType],
          isLoading: false,
          error: 'Server error',
          isValid: true,
        },
      };
    case VALIDATE_CONTRACT_BIRTH_NUMBER.LOADING:
      return {
        ...state,
        contract: {
          ...state.contract,
          isLoading: true,
          error: null,
          isValid: null,
        },
      };
    case VALIDATE_CONTRACT_BIRTH_NUMBER.SUCCESS:
      return {
        ...state,
        contract: {
          ...state.contract,
          isLoading: false,
          error: action.response.errorMessage,
          isValid: action.response.isValid,
        },
      };
    case VALIDATE_CONTRACT_BIRTH_NUMBER.ERROR:
      return {
        ...state,
        contract: {
          ...state.contract,
          isLoading: false,
          error: 'Server error',
          isValid: true,
        },
      };
    case VALIDATE_EMPLOYEE_NUMBER.LOADING:
      return {
        ...state,
        employee: {
          ...state.employee,
          isLoading: true,
          error: null,
          isValid: false,
        },
      };
    case VALIDATE_EMPLOYEE_NUMBER.SUCCESS:
      return {
        ...state,
        employee: {
          ...state.employee,
          isLoading: false,
          error: null,
          isValid: true,
        },
      };
    case VALIDATE_EMPLOYEE_NUMBER.ERROR:
      return {
        ...state,
        employee: {
          ...state.employee,
          isLoading: false,
          error: action.response.errorMessage,
          isValid: false,
        },
      };
    case VALIDATE_INSUREE_RELATION.LOADING:
      return {
        ...state,
        mother: {
          ...state.mother,
          isLoading: true,
          isValid: false,
          error: null,
        },
      };
    case VALIDATE_INSUREE_RELATION.SUCCESS:
      return {
        ...state,
        mother: {
          ...state.mother,
          isLoading: false,
          isValid: action.response.isValid,
          error: action.response.errorMessage,
        },
      };
    case VALIDATE_INSUREE_RELATION.ERROR:
      return {
        ...state,
        mother: {
          ...state.mother,
          isLoading: false,
          isValid: false,
          error: action.response.errorMessage,
        },
      };
    case RESET_REGISTRATION:
    case RESET_AFTER_CHANGE_INSURED_PERSON:
    case RESET_WITH_ZZ:
      return defaultState;
    default:
      return state;
  }
};
