import { WizardReducer } from '../../../types/wizard.d';

const mock: WizardReducer = {
  isFromZZ: false,
  step0: {
    undefined: {
      ocr: null,
    },
    insuree: {
      ocr: null,
    },
    legalRepresentative: {
      ocr: null,
    },
    isCompleted: false,
  },
  step1: {
    // @ts-ignore
    person: null,
    // @ts-ignore
    insuranceCompany: null,
    isCompleted: false,
    nationality: null,
  },
  step2: {
    insuree: {
      birthNumber: null,
      dateOfBirth: '',
      firstName: null,
      lastName: null,
      birthName: null,
      idNumber: null,
      nationality: null,
      nationalityIsEU: null,
      sex: null,
      academicDegreeBeforeName: null,
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      temporaryAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {
        isOCR: false,
      },
      havePostalAddress: false,
      haveTemporaryAddress: false,
      stayFrom: null,
      stayTo: null,
    },
    legalRepresentative: {
      birthNumber: null,
      dateOfBirth: '',
      firstName: null,
      lastName: null,
      birthName: null,
      idNumber: null,
      nationality: null,
      nationalityIsEU: null,
      sex: null,
      academicDegreeBeforeName: null,
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      temporaryAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {
        isOCR: false,
      },
      havePostalAddress: false,
      haveTemporaryAddress: false,
      stayFrom: null,
      stayTo: null,
    },
    isCompleted: false,
  },
  step3: {
    insurancePayerType: null,
    employer: null,
    selfEmployed: null,
    state: null,
    stateOther: null,
    retiree: null,
    student: null,
    homeWithChild: null,
    icoSZCO: null,
    nazovSZCO: null,
    isCompleted: false,
  },
  step4: {
    email: null,
    phone: null,
    relation: null,
    motherInsuranceCompany: null,
    motherBirthNumber: null,
    deliveryAddressType: null,
    deliveryAddress: null,
    courierAddress: null,
    strongConsent: false,
    weakConsent: false,
    enableToLogin: true,
    isCompleted: false,
    relationship: null,
    deliveryName: null,
  },
  step5: {
    signType: null,
    phoneNumberForSMS: null,
    isCompleted: false,
  },
};

export default mock;
