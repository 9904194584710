import cardBenefit from '../../assets/illustrations/card-benefit.svg';
import cardRegistration from '../../assets/illustrations/card-registration.svg';
//  @ts-ignore
import cardDohody from '../../assets/illustrations/card-dohody.jpg';
//  @ts-ignore
import cardVyplatnePasky from '../../assets/illustrations/card-vyplatne-pasky.jpg';
import idBackSide from '../../assets/illustrations/id-back-side.svg';
import idError from '../../assets/illustrations/id-error.svg';
import idFrontSide from '../../assets/illustrations/id-front-side.svg';
import idSuccess from '../../assets/illustrations/id-success.svg';
import rodicia from '../../assets/illustrations/rodicia.svg';
import terminationCourier from '../../assets/illustrations/termination-courier.svg';
import terminationPrinter from '../../assets/illustrations/termination-printer.svg';
import terminationPrinterDone from '../../assets/illustrations/termination-printer-done.svg';
import terminationSignatureError from '../../assets/illustrations/large_error_state.svg';
import terminationSignatureSuccess from '../../assets/illustrations/large_sucess_state.svg';
import terminationContractSuccess from '../../assets/illustrations/termination-contract-success.svg';
import zdraveZuby from '../../assets/illustrations/zdrave-zuby-100x100.svg';
import dnaAnalyza from '../../assets/illustrations/dna-analyza-100x100.svg';
import zlava from '../../assets/illustrations/discounts/zlava.svg';

import emailOfferResultError from '../../assets/illustrations/email-offer-result-error.svg';
import emailOfferResultSuccess from '../../assets/illustrations/email-offer-result-success.svg';

import ocrKid from '../../assets/illustrations/op-dieta.svg';
import ocrKidSuccess from '../../assets/illustrations/op-dieta-uspech.svg';
import ocrKidFailure from '../../assets/illustrations/op-dieta-chyba.svg';
import ocrKidInProgress from '../../assets/illustrations/op-dieta-process.svg';
import ocrKidCopied from '../../assets/illustrations/op-dieta-copied.svg';

import ocrAdult from '../../assets/illustrations/op-dospely.svg';
import ocrAdultSuccess from '../../assets/illustrations/op-dospely-uspech.svg';
import ocrAdultFailure from '../../assets/illustrations/op-dospely-chyba.svg';
import ocrAdultInProgress from '../../assets/illustrations/op-dospely-process.svg';
import ocrAdultCopied from '../../assets/illustrations/op-dospely-copied.svg';

import ocrSkip from '../../assets/illustrations/ocr-preskocene.svg';
import ocrSuccess from '../../assets/illustrations/ocr-uspech.svg';
import ocrFailure from '../../assets/illustrations/ocr-zlyhanie.svg';
import ocrInProcess from '../../assets/illustrations/ocr-process.svg';
import ocrCopied from '../../assets/illustrations/ocr-copied.svg';

import preplacanieProdukcia from '../../assets/illustrations/preplacanie.svg';

import scanOPFront from '../../assets/illustrations/scan-op-front.svg';
import scanOPBack from '../../assets/illustrations/scan-op-back.svg';

import ocrPhotoTaken from '../../assets/illustrations/ocr-contract-success.svg';

// Zľavy v optikách Fokus
import benefit_okuliare_pre_deti from '../../assets/illustrations/benefits/benefit_zdrave_oci.svg';
// tv balance
import tvBalanceLarge from '../../assets/illustrations/tv-balance-400x312.svg';
import tvBalance from '../../assets/illustrations/tv-balance-100x100.svg';
// kozne vysetrenie
import benefit_kozne_vysetrenie from '../../assets/illustrations/benefits/benefit_kozne_vysetrenie.svg';
// Nástroj na samodiagnostiku
import benefit_samodiagnostika from '../../assets/illustrations/benefits/benefit_samodiagnostika.svg';

import benefit_gift from '../../assets/illustrations/benefits/benefit_gift.svg';
import benefit_gift_heart from '../../assets/illustrations/benefits/benefit_gift_heart.svg';
import benefit_weight from '../../assets/illustrations/benefits/benefit_weight.svg';
// Cytologicke vysetrenie
import cytologickeVysetrenieLBC from '../../assets/illustrations/benefits/benefit_cytologicke_vysetrenie.svg';
// Skrining SMA u novorodencov
import skriningSMALarge from '../../assets/illustrations/skrining-SMA-u-novorodencov-400x312.svg';
import skriningSMA from '../../assets/illustrations/skrining-SMA-u-novorodencov-100x100.svg';
// Bory doprava
import boryDoprava from '../../assets/illustrations/benefits/benefit_doprava.svg';
// 300 € za lieky
import benefit_300 from '../../assets/illustrations/benefits/benefit_300.svg';
// Príspevok na dobrovoľné očkovania
import prispevokOckovanie from '../../assets/illustrations/benefits/benefit_prispevok_na_ockovanie.svg';
import dovera_logo from '../../assets/illustrations/benefits/benefit_dovera.svg';
import benefit_zuby from '../../assets/illustrations/benefits/benefit_zuby.svg';
import linkaPreZdravie from '../../assets/illustrations/benefits/benefit_linka_pre_zdravie.svg';
import objednanieKLekarovi from '../../assets/illustrations/benefits/benefit_objednanie_prednostne.svg';
import mobilna_app from '../../assets/illustrations/benefits/benefit_mobilna_appka.svg';
import benefit_electronic_branch from '../../assets/illustrations/benefits/benefit_electronic_branch.svg';
import benefit_vip_izba from '../../assets/illustrations/benefits/benefit_vip_izba.svg';
import benefit_nemocnica_bory from '../../assets/illustrations/benefits/benefit_nemocnica_bory.svg';
import benefit_animoterapia from '../../assets/illustrations/benefits/benefit_animoterapia.svg';
import benefit_jednotka_zdravia from '../../assets/illustrations/benefits/benefit_jednotka_zdravia.svg';
import benefit_lekar_na_dialku from '../../assets/illustrations/benefits/benefit_lekar_na_dialku.svg';
import benefit_crp from '../../assets/illustrations/benefits/benefit_crp.svg';
import benefit_gift_euro from '../../assets/illustrations/benefits/benefit_gift_euro.svg';
import benefit_lacnejsie_kupele from '../../assets/illustrations/benefits/benefit_lacnejsie_kupele.svg';
import benefit_gift_blood from '../../assets/illustrations/benefits/benefit_gift_blood.svg';
import benefit_zlavy_partnery from '../../assets/illustrations/benefits/benefit_zlavy_partnery.svg';
import benefit_cviky_pre_diabetikov from '../../assets/illustrations/benefits/benefit_cviky_pre_diabetikov.svg';

import pomahameSoSrdcom from '../../assets/illustrations/benefits/benefit_so_srdcom.svg';
import zmluvaLekari from '../../assets/illustrations/benefits/benefit_lekari.svg';
import bojovniciZdravie from '../../assets/illustrations/benefits/benefit_bojovnici_za_zdravie.svg';
import darcaKrvi from '../../assets/illustrations/benefits/benefit_darca_krvi.svg';

import vymenaKlbu from '../../assets/illustrations/benefits/benefit_nadstandartna_starostlivost_vymena_klbu.svg';
import benefit_mamografia from '../../assets/illustrations/benefits/benefit_mamografia.svg';
import lieky from '../../assets/illustrations/benefits/benefit_lieky.svg';
import festivalZdravia from '../../assets/illustrations/benefits/benefit_festival_zdravia.svg';
import hrubeCrevoBenefit from '../../assets/illustrations/benefits/benefit_vysetrenie_hrubeho_creva.svg';
import interakciaLiekov from '../../assets/illustrations/benefits/benefit_nevhodne_interakcie_liekov.svg';
import mobilnaAppVOS from '../../assets/illustrations/benefits/benefit_mobil.svg';

// ZLAVY
import zlava_05 from '../../assets/illustrations/discounts/zlava_05.svg';
import zlava_15 from '../../assets/illustrations/discounts/zlava_15.svg';
import zlava_20 from '../../assets/illustrations/discounts/zlava_20.svg';
import zlava_25 from '../../assets/illustrations/discounts/zlava_25.svg';
import zlava_30 from '../../assets/illustrations/discounts/zlava_30.svg';
import zlava_40 from '../../assets/illustrations/discounts/zlava_40.svg';
import zlava_50 from '../../assets/illustrations/discounts/zlava_50.svg';
import zlava_80 from '../../assets/illustrations/discounts/zlava_80.svg';
import zlava_procare from '../../assets/illustrations/discounts/zlava_procare.svg';
import zlava_baby from '../../assets/illustrations/discounts/zlava_duck.svg';
import zlava_dna from '../../assets/illustrations/discounts/zlava_dna_testy.svg';
import zlava_psycho from '../../assets/illustrations/discounts/zlava_psychologicke_poradenstvo.svg';

import odberKrvi from '../../assets/illustrations/benefits/benefit_odber.svg';
import tab_vseobecne from '../../assets/illustrations/benefits/tab_vseobecne.svg';
import tab_chory from '../../assets/illustrations/benefits/tab_chory.svg';
import tab_babatko from '../../assets/illustrations/benefits/tab_babatko.svg';
import tab_mlady from '../../assets/illustrations/benefits/tab_mlady.svg';
import tab_rodina from '../../assets/illustrations/benefits/tab_rodina.svg';
import tab_senior from '../../assets/illustrations/benefits/tab_senior.svg';

import benefit_rodina from '../../assets/illustrations/benefits/benefit_rodina.svg';

export default {
  zlava_05,
  zlava_15,
  zlava_20,
  zlava_25,
  zlava_30,
  zlava_40,
  zlava_50,
  zlava_80,
  zlava_procare,
  zlava_baby,
  zlava_dna,
  zlava_psycho,
  vymenaKlbu,
  benefit_mamografia,
  benefit_rodina,
  lieky,
  festivalZdravia,
  hrubeCrevoBenefit,
  interakciaLiekov,
  mobilnaAppVOS,
  mobilna_app,
  benefit_electronic_branch,
  benefit_okuliare_pre_deti,
  benefit_vip_izba,
  benefit_cviky_pre_diabetikov,
  benefit_nemocnica_bory,
  benefit_animoterapia,
  benefit_jednotka_zdravia,
  benefit_lekar_na_dialku,
  benefit_zlavy_partnery,
  benefit_crp,
  benefit_gift_euro,
  benefit_lacnejsie_kupele,
  benefit_gift_blood,
  cardBenefit,
  cardRegistration,
  cardDohody,
  cardVyplatnePasky,
  odberKrvi,
  emailOfferResultError,
  emailOfferResultSuccess,
  idBackSide,
  idError,
  benefit_zuby,
  idFrontSide,
  idSuccess,
  ocrAdult,
  ocrAdultFailure,
  ocrAdultSuccess,
  ocrAdultInProgress,
  ocrAdultCopied,
  ocrFailure,
  ocrInProcess,
  ocrSuccess,
  ocrSkip,
  ocrCopied,
  ocrKid,
  ocrKidFailure,
  ocrKidSuccess,
  ocrKidInProgress,
  ocrKidCopied,
  ocrPhotoTaken,
  rodicia,
  terminationCourier,
  terminationPrinter,
  terminationPrinterDone,
  terminationSignatureError,
  terminationSignatureSuccess,
  terminationContractSuccess,
  tab_vseobecne,
  tab_rodina,
  tab_babatko,
  tab_chory,
  tab_mlady,
  tab_senior,
  zdraveZuby,
  preplacanieProdukcia,
  dnaAnalyza,
  scanOPFront,
  scanOPBack,
  tvBalanceLarge,
  tvBalance,
  zlava,
  benefit_kozne_vysetrenie,
  benefit_samodiagnostika,
  benefit_gift,
  benefit_gift_heart,
  benefit_weight,
  cytologickeVysetrenieLBC,
  skriningSMA,
  skriningSMALarge,
  boryDoprava,
  benefit_300,
  prispevokOckovanie,
  dovera_logo,
  linkaPreZdravie,
  objednanieKLekarovi,
  pomahameSoSrdcom,
  zmluvaLekari,
  bojovniciZdravie,
  darcaKrvi,
};
