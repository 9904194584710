import { WizardReducer } from '../../../types/wizard.d';

const mock: WizardReducer = {
  step0: {
    undefined: {
      ocr: null,
    },
    insuree: {
      ocr: null,
    },
    legalRepresentative: {
      ocr: null,
    },
    isCompleted: true,
  },
  step1: {
    //  @ts-ignore
    person: 'adult',
    //  @ts-ignore
    insuranceCompany: 'union',
    isCompleted: true,
  },
  step2: {
    insuree: {
      birthNumber: '9201023469',
      dateOfBirth: '1992-01-01T23:00:00.000Z',
      firstName: 'Karolína',
      lastName: 'Motúziková',
      birthName: 'Kadejaká',
      idNumber: 'SL134325',
      nationality: 'SK',
      nationalityIsEU: null,
      sex: null,
      academicDegreeBeforeName: 'Ing.',
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: 'KROMPACHY',
        cislo: '4',
        psc: '05342',
        ulicaNazov: null,
        statNazov: 'SLOVENSKÁ REPUBLIKA',
        statKod: 'SK',
        obecKod: '543268',
      },
      temporaryAddress: {
        obecNazov: 'KOŠICE-BARCA',
        cislo: '13',
        psc: '04017',
        ulicaNazov: 'BARČIANSKA',
        statKod: 'SK',
        statNazov: 'SLOVENSKÁ REPUBLIKA',
        obecKod: '599093',
        ulicaKod: '246369',
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {},
      havePostalAddress: false,
      haveTemporaryAddress: true,
      stayFrom: null,
      stayTo: null,
    },
    legalRepresentative: {
      birthNumber: null,
      dateOfBirth: '',
      firstName: null,
      lastName: null,
      birthName: null,
      idNumber: null,
      nationality: null,
      nationalityIsEU: null,
      sex: null,
      academicDegreeBeforeName: null,
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      temporaryAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {
        isOCR: false,
      },
      havePostalAddress: false,
      haveTemporaryAddress: false,
      stayFrom: null,
      stayTo: null,
    },
    isCompleted: true,
  },
  step3: {
    //  @ts-ignore
    insurancePayerType: 'employer',
    employer: {
      id: '4259672',
      nazov: 'SENTIA S.R.O.',
      typ: 'P',
      ico: '36708445',
      varSymbol: '3670844500',
      specSymbol: '10101',
      adresaSidla: {
        id: 16338562,
        typ: 'AS',
        ulicaKod: '26539',
        ulicaNazov: 'MARTINČEKOVA',
        cislo: '17',
        obecKod: '582000',
        obecNazov: 'BRATISLAVA',
        psc: '82101 ',
        lokalitaId: null,
        lokalitaNazov: null,
        statKod: 'SK',
        statNazov: 'SLOVENSKÁ REPUBLIKA',
      },
    },
    selfEmployed: null,
    state: null,
    stateOther: null,
    retiree: null,
    student: null,
    homeWithChild: null,
    icoSZCO: null,
    nazovSZCO: null,
    isCompleted: true,
  },
  step4: {
    email: 'drabikova.anna@dovera.sk',
    phone: null,
    relation: null,
    motherInsuranceCompany: null,
    motherBirthNumber: null,
    //  @ts-ignore
    deliveryAddressType: 'branchOffice',
    deliveryAddress: 5700,
    courierAddress: null,
    strongConsent: true,
    weakConsent: true,
    enableToLogin: true,
    isCompleted: true,
  },
  step5: {
    //  @ts-ignore
    signType: 'print',
    phoneNumberForSMS: null,
    isCompleted: true,
  },
};

export default mock;
