import { ExtranetReducer } from '../types/reducers.d';
import {
  GET_APPLICATION_LIST,
  GET_EMPLOYEE_LIST,
  GET_PAYOUTS_LIST,
  GET_PAYOUT_DOCUMENT,
  GET_EMPLOYEE_DOCUMENT,
  GET_CONTRACTS_LIST,
  GET_CONTRACTS_MANAGEMENT_LIST,
  GET_CONTRACTS_MANAGEMENT_DETAIL,
  GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS,
  GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS,
  SEND_CONTRACT_TO_TXS,
  DELETE_CONTRACT_DOCUMENT,
  DOWNLOAD_CONTRACT_DOCUMENT,
  CANCEL_CONTRACT,
  SIGN_CONTRACT,
  UPDATE_SUPERIORS,
  UPDATE_RETIREMENT,
  UPLOAD_CONTRACT_DOCUMENT,
  DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT,
  GET_LIST_TERMINATED_CONTRACT,
  SAVE_USER_CONTACT,
  SAVE_USER_CONTACT_CLEAR,
  GET_EMPLOYEE_DOCUMENTS_LIST,
} from '../api/actionSets';
import {
  SET_PAYOUT_AS_DOWNLOADED,
  SET_DOCUMENTS_AS_DOWNLOADED,
  SET_CONTRACT_DOCUMENT_AS_DOWNLOADED,
  SET_SUBORDINATES_FILTER,
  RESET_TERMINATED_DOWNLOAD_MODAL,
  RESET_ENDED_DOWNLOADED_CONTRACT,
} from '../actions/extranet-actions';
import { dateFilterInit } from '../utils/dateUtils';

const initNotificationState = {
  isLoading: false,
  isLoaded: false,
  statusMessage: null,
  result: null,
  txsContract: false,
};

const defaultState: ExtranetReducer = {
  application: {
    isLoading: false,
    filter: null,
    list: null,
  },
  subordinates: {
    isLoading: false,
    dateFilter: dateFilterInit,
    list: null,
  },
  payout: {
    isLoading: false,
    list: null,
    toDownload: null,
    downloading: {},
  },
  documents: {
    isLoading: false,
    list: null,
    toDownload: null,
    downloading: {},
  },
  contract: {
    isLoading: false,
    list: null,
  },
  contractTermination: {
    isLoading: false,
    terminatedContractFile: null,
  },
  contractManagement: {
    isLoading: false,
    list: null,
    filter: null,
  },
  contractTerminateds: {
    isLoading: false,
    list: null,
    filter: null,
  },
  contractManagementDetail: {
    detail: {
      data: null,
      isLoading: false,
      isLoaded: false,
    },
    actionButtons: {
      isVisibleResendContract: false,
      isVisibleSignedEmployer: false,
      isVisibleStorno: false,
      messageTXS: null,
      successTXS: false,
      stornoReason: null,
      isLoading: false,
      isLoaded: false,
    },
    notification: initNotificationState,
    documents: {
      list: [],
      isLoading: true,
      isLoaded: false,
      downloading: {},
      toDownload: [],
      deleting: [],
    },
    signature: {
      isLoading: false,
      success: false,
      documentBioSignKey: null,
      xfdf: null,
    },
    updateRetirement: {
      isLoaded: false,
      isLoading: false,
      success: false,
    },
    updateSupervisors: {
      isLoaded: false,
      isLoading: false,
      success: false,
    },
    userContactUpdate: {
      isLoaded: false,
      isLoading: false,
      responseObj: null,
    },
  },
};

export default (state: ExtranetReducer = defaultState, action) => {
  switch (action.type) {
    // GET_APPLICATION_LIST
    case GET_APPLICATION_LIST.LOADING:
      return {
        ...state,
        application: {
          ...state.application,
          isLoading: true,
        },
      };
    case GET_APPLICATION_LIST.SUCCESS:
      return {
        ...state,
        application: {
          ...state.application,
          isLoading: false,
          isLoaded: true,
          list: action.response.registrations,
          filter: action.response.filterData,
        },
      };
    case GET_APPLICATION_LIST.ERROR:
      return {
        ...state,
        application: {
          ...state.application,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    // GET_EMPLOYEE_LIST
    case GET_EMPLOYEE_LIST.LOADING:
      return {
        ...state,
        subordinates: {
          ...state.subordinates,
          isLoading: true,
        },
      };
    case GET_EMPLOYEE_LIST.SUCCESS:
      return {
        ...state,
        subordinates: {
          ...state.subordinates,
          isLoading: false,
          isLoaded: true,
          list: action.response.employeeRegistrations,
          // filter: action.response.filterData,
        },
      };
    case GET_EMPLOYEE_LIST.ERROR:
      return {
        ...state,
        subordinates: {
          ...state.subordinates,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    // SET SUBORDINATES FIlTER
    case SET_SUBORDINATES_FILTER:
      return {
        ...state,
        subordinates: {
          ...state.subordinates,
          dateFilter: action.filter,
        },
      };

    // GET_PAYOUT_DOCUMENT
    case GET_PAYOUT_DOCUMENT.LOADING:
      return {
        ...state,
        payout: {
          ...state.payout,
          downloading: {
            ...state.payout.downloading,
            [action.payoutId]: {
              loading: true,
              name: null,
              content: null,
            },
          },
        },
      };
    case GET_PAYOUT_DOCUMENT.SUCCESS:
      return {
        ...state,
        payout: {
          ...state.payout,
          toDownload: action.payoutId,
          downloading: {
            ...state.payout.downloading,
            [action.payoutId]: {
              loading: false,
              name: action.response.fileName,
              content: action.response.content,
              type: action.response.documentMime,
            },
          },
        },
      };
    case GET_PAYOUT_DOCUMENT.ERROR:
      return {
        ...state,

        payout: {
          ...state.payout,
          downloading: {
            ...state.payout.downloading,
            [action.payoutId]: {
              loading: false,
              error: true,
            },
          },
        },
      };
    case SET_PAYOUT_AS_DOWNLOADED: {
      const list = { ...state.payout.downloading };
      delete list[action.id];
      return {
        ...state,
        payout: {
          ...state.payout,
          toDownload: null,
          downloading: list,
        },
      };
    }
    // GET_EMPLOYEE_DOCUMENT
    case GET_EMPLOYEE_DOCUMENT.LOADING:
      return {
        ...state,
        documents: {
          ...state.documents,
          downloading: {
            ...state.documents.downloading,
            [action.documentUuid]: {
              loading: true,
              name: null,
              content: null,
            },
          },
        },
      };
    case GET_EMPLOYEE_DOCUMENT.SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          toDownload: action.documentUuid,
          downloading: {
            ...state.documents.downloading,
            [action.documentUuid]: {
              loading: false,
              name: action.response.name,
              content: action.response.content,
              type: action.response.mimeType,
            },
          },
        },
      };
    case GET_EMPLOYEE_DOCUMENT.ERROR:
      return {
        ...state,
        documents: {
          ...state.documents,
          downloading: {
            ...state.documents.downloading,
            [action.documentUuid]: {
              loading: false,
              error: true,
            },
          },
        },
      };
    case SET_DOCUMENTS_AS_DOWNLOADED: {
      const list = { ...state.documents.downloading };
      delete list[action.id];
      return {
        ...state,
        documents: {
          ...state.documents,
          toDownload: null,
          downloading: list,
        },
      };
    }
    // GET_EMPLOYEE_DOCUMENTS_LIST
    case GET_EMPLOYEE_DOCUMENTS_LIST.LOADING:
      return {
        ...state,
        documents: {
          ...state.documents,
          isLoading: true,
        },
      };
    case GET_EMPLOYEE_DOCUMENTS_LIST.SUCCESS:
      return {
        ...state,
        documents: {
          ...state.payout,
          isLoading: false,
          isLoaded: true,
          list: action.response,
        },
      };
    case GET_EMPLOYEE_DOCUMENTS_LIST.ERROR:
      return {
        ...state,
        documents: {
          ...state.documents,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    // GET_PAYOUTS_LIST
    case GET_PAYOUTS_LIST.LOADING:
      return {
        ...state,
        payout: {
          ...state.payout,
          isLoading: true,
        },
      };
    case GET_PAYOUTS_LIST.SUCCESS:
      return {
        ...state,
        payout: {
          ...state.payout,
          isLoading: false,
          isLoaded: true,
          list: action.response.payouts,
        },
      };
    case GET_PAYOUTS_LIST.ERROR:
      return {
        ...state,
        payout: {
          ...state.payout,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    // GET_CONTRACTS_LIST
    case GET_CONTRACTS_LIST.LOADING:
      return {
        ...state,
        contract: {
          ...state.contract,
          isLoading: true,
        },
      };
    case GET_CONTRACTS_LIST.SUCCESS:
      return {
        ...state,
        contract: {
          ...state.contract,
          isLoading: false,
          isLoaded: true,
          list: action.response.contracts,
        },
      };
    case GET_CONTRACTS_LIST.ERROR:
      return {
        ...state,
        contract: {
          ...state.contract,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    // GET_CONTRACTS_MANAGEMENT_LIST
    case GET_CONTRACTS_MANAGEMENT_LIST.LOADING:
      return {
        ...state,
        contractManagement: {
          ...state.contractManagement,
          isLoading: true,
        },
      };
    case GET_CONTRACTS_MANAGEMENT_LIST.SUCCESS:
      return {
        ...state,
        contractManagement: {
          ...state.contractManagement,
          isLoading: false,
          isLoaded: true,
          list: action.response.contracts,
        },
      };
    case GET_CONTRACTS_MANAGEMENT_LIST.ERROR:
      return {
        ...state,
        contractManagement: {
          ...state.contractManagement,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };
    // GET_CONTRACTS_MANAGEMENT_DETAIL
    case GET_CONTRACTS_MANAGEMENT_DETAIL.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          detail: {
            ...state.contractManagementDetail.detail,
            isLoading: true,
          },
        },
      };
    case GET_CONTRACTS_MANAGEMENT_DETAIL.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          detail: {
            ...state.contractManagementDetail.detail,
            isLoading: false,
            isLoaded: true,
            data: action.response,
          },
        },
      };
    case GET_CONTRACTS_MANAGEMENT_DETAIL.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          detail: {
            ...state.contractManagementDetail.detail,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
        },
      };
    // case UPLOAD_CONTRACT_DOCUMENT.LOADING:
    case GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            isLoading: true,
            isLoaded: false,
            list: [],
          },
        },
      };
    case UPLOAD_CONTRACT_DOCUMENT.SUCCESS:
    case GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            isLoading: false,
            isLoaded: true,
            list: action.response.documents,
          },
        },
      };
    case UPLOAD_CONTRACT_DOCUMENT.ERROR:
    case GET_CONTRACTS_MANAGEMENT_DETAIL_DOCUMENTS.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            isLoading: false,
            isLoaded: false,
            error: action.error,
            list: [],
          },
        },
      };
    case GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          actionButtons: {
            ...state.contractManagementDetail.actionButtons,
            isLoading: true,
          },
          notification: {
            ...state.contractManagementDetail.notification,
            ...initNotificationState,
            isLoading: true,
          },
        },
      };
    case GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          actionButtons: {
            ...state.contractManagementDetail.actionButtons,
            isLoading: false,
            isLoaded: true,
            ...action.response,
          },
          notification: {
            ...state.contractManagementDetail.notification,
            isLoading: false,
            isLoaded: true,
            statusMessage: action.response.messageTXS,
            result: action.response.successTXS,
            txsContract: action.response.successTXS,
          },
        },
      };
    case GET_CONTRACTS_MANAGEMENT_DETAIL_BUTTONS.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          actionButtons: {
            ...state.contractManagementDetail.actionButtons,
            isLoading: false,
            isLoaded: false,
            error: action.error,
          },
          notification: {
            ...state.contractManagementDetail.notification,
            isLoading: false,
            isLoaded: true,
            statusMessage: action.error,
            result: false,
          },
        },
      };
    case SEND_CONTRACT_TO_TXS.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          notification: {
            ...state.contractManagementDetail.notification,
            ...initNotificationState,
            isLoading: true,
          },
        },
      };
    case SEND_CONTRACT_TO_TXS.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          notification: {
            ...state.contractManagementDetail.notification,
            isLoading: false,
            isLoaded: true,
            statusMessage: action.response.statusMessage,
            result: action.response.result,
          },
        },
      };
    case SEND_CONTRACT_TO_TXS.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          notification: {
            ...state.contractManagementDetail.notification,
            isLoading: false,
            isLoaded: true,
            statusMessage: action.error,
            result: false,
          },
        },
      };
    case CANCEL_CONTRACT.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          notification: {
            ...state.contractManagementDetail.notification,
            ...initNotificationState,
            isLoading: true,
          },
        },
      };
    case CANCEL_CONTRACT.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          notification: {
            ...state.contractManagementDetail.notification,
            isLoading: false,
            isLoaded: true,
            statusMessage: action.response.statusMessage,
            result: action.response.success,
          },
        },
      };
    case CANCEL_CONTRACT.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          notification: {
            ...state.contractManagementDetail.notification,
            isLoading: false,
            isLoaded: true,
            statusMessage: action.error,
            result: false,
          },
        },
      };
    case DELETE_CONTRACT_DOCUMENT.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            deleting: [
              ...state.contractManagementDetail.documents.deleting,
              action.documentToken,
            ],
          },
        },
      };
    case DELETE_CONTRACT_DOCUMENT.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            list: action.response.documents,
            deleting: state.contractManagementDetail.documents.deleting.filter(
              d => d !== action.documentToken,
            ),
          },
        },
      };
    case DELETE_CONTRACT_DOCUMENT.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            loadingError: action.response,
            deleting: state.contractManagementDetail.documents.deleting.filter(
              d => d !== action.documentToken,
            ),
          },
        },
      };
    case DOWNLOAD_CONTRACT_DOCUMENT.LOADING:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            downloading: {
              ...state.contractManagementDetail.documents.downloading,
              [action.documentToken]: {
                loading: true,
                name: null,
                content: null,
              },
            },
          },
        },
      };
    case DOWNLOAD_CONTRACT_DOCUMENT.SUCCESS:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            downloading: {
              ...state.contractManagementDetail.documents.downloading,
              [action.documentToken]: {
                loading: false,
                name: action.response.fileName,
                content: action.response.content,
                type: action.response.documentMime,
              },
            },
            toDownload: [
              ...state.contractManagementDetail.documents.toDownload,
              action.documentToken,
            ],
          },
        },
      };
    case DOWNLOAD_CONTRACT_DOCUMENT.ERROR:
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            downloading: {
              ...state.contractManagementDetail.documents.downloading,
              [action.documentToken]: {
                loading: false,
                error: true,
              },
            },
          },
        },
      };
    case SET_CONTRACT_DOCUMENT_AS_DOWNLOADED: {
      const list = { ...state.contractManagementDetail.documents.downloading };
      delete list[action.token];
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          documents: {
            ...state.contractManagementDetail.documents,
            toDownload: state.contractManagementDetail.documents.toDownload.filter(
              td => td !== action.token,
            ),
            downloading: list,
          },
        },
      };
    }
    case SIGN_CONTRACT.LOADING: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          signature: {
            ...state.contractManagementDetail.signature,
            isLoading: true,
            isLoaded: false,
            success: false,
          },
        },
      };
    }
    case SIGN_CONTRACT.SUCCESS: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          signature: {
            ...state.contractManagementDetail.signature,
            isLoading: false,
            isLoaded: true,
            ...action.response,
          },
        },
      };
    }
    case SIGN_CONTRACT.ERROR: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          signature: {
            ...state.contractManagementDetail.signature,
            isLoading: false,
            isLoaded: false,
            loadingError: action.response,
          },
        },
      };
    }
    case RESET_ENDED_DOWNLOADED_CONTRACT: {
      return {
        ...state,
        contractTermination: {
          isLoading: false,
          isLoaded: false,
          success: false,
          error: null,
          terminatedContractFile: null,
        },
      };
    }
    case DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT.LOADING: {
      return {
        ...state,
        contractTermination: {
          ...state.contractTermination,
          isLoading: true,
          isLoaded: false,
          success: false,
          error: null,
        },
      };
    }
    case DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT.SUCCESS: {
      return {
        ...state,
        contractTermination: {
          ...state.contractTermination,
          isLoading: false,
          isLoaded: true,
          success: true,
          error: null,
          terminatedContractFile: action.response,
        },
      };
    }
    case RESET_TERMINATED_DOWNLOAD_MODAL: {
      return {
        ...state,
        contractTermination: {
          ...state.contractTermination,
          isLoading: false,
          isLoaded: false,
          success: false,
          error: null,
          terminatedContractFile: null,
        },
      };
    }
    case DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT.ERROR: {
      return {
        ...state,
        contractTermination: {
          ...state.contractTermination,
          isLoading: false,
          isLoaded: true,
          success: false,
          error: action.rawError,
        },
      };
    }
    case GET_LIST_TERMINATED_CONTRACT.LOADING: {
      return {
        ...state,
        contractTerminateds: {
          ...state.contractTerminateds,
          isLoading: true,
          isLoaded: false,
          success: false,
          error: null,
        },
      };
    }
    case GET_LIST_TERMINATED_CONTRACT.SUCCESS: {
      return {
        ...state,
        contractTerminateds: {
          ...state.contractTerminateds,
          isLoading: false,
          isLoaded: true,
          success: true,
          list: action.response?.terminatedContracts || [],
        },
      };
    }
    case GET_LIST_TERMINATED_CONTRACT.ERROR: {
      return {
        ...state,
        contractTerminateds: {
          ...state.contractTerminateds,
          isLoading: false,
          isLoaded: true,
          success: false,
          loadingError: action.response,
        },
      };
    }
    case UPDATE_RETIREMENT.LOADING: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          updateRetirement: {
            ...state.contractManagementDetail.updateRetirement,
            isLoading: true,
            isLoaded: false,
            success: false,
          },
        },
      };
    }
    case UPDATE_RETIREMENT.SUCCESS: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          updateRetirement: {
            ...state.contractManagementDetail.updateRetirement,
            isLoading: false,
            isLoaded: true,
            success: action.response.success,
          },
        },
      };
    }
    case UPDATE_RETIREMENT.ERROR: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          updateRetirement: {
            ...state.contractManagementDetail.updateRetirement,
            isLoading: false,
            isLoaded: true,
            success: action.response.success,
          },
        },
      };
    }
    case UPDATE_SUPERIORS.LOADING: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          updateSupervisors: {
            ...state.contractManagementDetail.updateSupervisors,
            isLoading: true,
            isLoaded: false,
            success: false,
          },
        },
      };
    }
    case UPDATE_SUPERIORS.SUCCESS: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          updateSupervisors: {
            ...state.contractManagementDetail.updateSupervisors,
            isLoading: false,
            isLoaded: true,
            success: action.response.success,
          },
        },
      };
    }
    case UPDATE_SUPERIORS.ERROR: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          updateSupervisors: {
            ...state.contractManagementDetail.updateSupervisors,
            isLoading: false,
            isLoaded: true,
            success: action.response.success,
          },
        },
      };
    }
    case SAVE_USER_CONTACT.LOADING: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          userContactUpdate: {
            ...state.contractManagementDetail.userContactUpdate,
            isLoading: true,
            isLoaded: false,
            responseObj: null,
          },
        },
      };
    }
    case SAVE_USER_CONTACT.SUCCESS: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          userContactUpdate: {
            ...state.contractManagementDetail.userContactUpdate,
            isLoading: false,
            isLoaded: true,
            responseObj: action.response,
          },
        },
      };
    }
    case SAVE_USER_CONTACT.ERROR: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          userContactUpdate: {
            ...state.contractManagementDetail.userContactUpdate,
            isLoading: false,
            isLoaded: true,
            responseObj: action.response,
          },
        },
      };
    }
    case SAVE_USER_CONTACT_CLEAR: {
      return {
        ...state,
        contractManagementDetail: {
          ...state.contractManagementDetail,
          userContactUpdate: {
            isLoading: false,
            isLoaded: false,
            responseObj: null,
          },
        },
      };
    }
    default:
      return state;
  }
};
