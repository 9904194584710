import { GET_RVM_CONTACTS } from '../api/actionSets';
import { CMSReducer } from '../types/reducers.d';

export const initialState: CMSReducer = {
  contacts: {
    data: null,
    isLoading: false,
  },
};

function cmsReducer(state: CMSReducer = initialState, action) {
  switch (action.type) {
    case GET_RVM_CONTACTS.LOADING:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          isLoading: true,
        },
      };
    case GET_RVM_CONTACTS.SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: action.response.rvmProfilContactGroups,
          isLoading: false,
          isLoaded: true,
        },
      };
    case GET_RVM_CONTACTS.ERROR:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          data: null,
          isLoading: false,
          isLoaded: true,
        },
      };
    default:
      return state;
  }
}

export default cmsReducer;
