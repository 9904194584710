import { SubordinatesFilter } from '../types/reducers';

export const SET_PAYOUT_AS_DOWNLOADED = 'SET_PAYOUT_AS_DOWNLOADED';
export const SET_DOCUMENTS_AS_DOWNLOADED = 'SET_DOCUMENTS_AS_DOWNLOADED0;';
export const SET_CONTRACT_DOCUMENT_AS_DOWNLOADED =
  'SET_CONTRACT_DOCUMENT_AS_DOWNLOADED';
export const SET_SUBORDINATES_FILTER = 'SET_SUBORDINATES_FILTER';
export const RESET_TERMINATED_DOWNLOAD_MODAL =
  'RESET_TERMINATED_DOWNLOAD_MODAL';

export const RESET_ENDED_DOWNLOADED_CONTRACT =
  'RESET_ENDED_DOWNLOADED_CONTRACT';

export const setPayoutAsDownloaded = (id: string) => ({
  type: SET_PAYOUT_AS_DOWNLOADED,
  id,
});
export const setDocumentsAsDownloaded = (id: string) => ({
  type: SET_DOCUMENTS_AS_DOWNLOADED,
  id,
});
export const setContractDocumentAsDownloaded = (token: string) => ({
  type: SET_CONTRACT_DOCUMENT_AS_DOWNLOADED,
  token,
});
export const setSubordinatesFilter = (filter: SubordinatesFilter) => ({
  type: SET_SUBORDINATES_FILTER,
  filter,
});
export const resetTerminatedContractModal = () => ({
  type: RESET_TERMINATED_DOWNLOAD_MODAL,
});
export const resetEndedDownloadedContract = () => ({
  type: RESET_ENDED_DOWNLOADED_CONTRACT,
});
