import * as actionSets from './actionSets';
import { createUrl, createBodyLog, callApiRequest } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { MOCK_API_CALL } from '../middleware/mockApiMiddleware';
import { Nullable } from '../types/index.d';
import { UserCredentialsInput } from '../types/loginControl.d';

interface ChangeResponse {
  success: boolean;
  message: Nullable<string>;
}

const controller = 'User';

const auth = {
  // , userID: Nullable<string>
  getUserInfo: (relationID: number) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetUserInfo`),
      method: 'POST',
      body: createBodyLog({ relationID }),
      actions: actionSets.DAJ_UDAJE_POUZIVATELA,
    },
  }),
  getHashUser: (internalNumber: string, headers: any): Promise<string> =>
    callApiRequest(`${controller}/GetHashUser`, { internalNumber }, headers)
      .then((data: any) => data.result)
      .catch(() => {
        return null;
      }),
  changeLogin: (login: string, token: any): Promise<ChangeResponse> =>
    callApiRequest(
      `${controller}/ChangeLogin`,
      { login },
      {
        Authorization: `Bearer ${token}`,
      },
    ).then((data: any) => data),
  changePassword: (oldPassword: string, newPassword: string) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ChangePassword`),
      method: 'POST',
      body: createBodyLog({ oldPassword, newPassword }),
      actions: actionSets.CHANGE_PASSWORD,
    },
  }),
  changeEmail: (emailAdresa: string, token: any): Promise<ChangeResponse> =>
    callApiRequest(
      `${controller}/ChangeEmail`,
      { emailAdresa },
      {
        Authorization: `Bearer ${token}`,
      },
    ).then((data: any) => data),
  changeMobile: (mobilKontakt: string, token: any): Promise<ChangeResponse> =>
    callApiRequest(
      `${controller}/ChangeMobile`,
      { mobilKontakt: mobilKontakt.replace(/\s/g, '') },
      {
        Authorization: `Bearer ${token}`,
      },
    ).then((data: any) => data),
  changeIBAN: (iban: string, internalNumber: string) => ({
    [API_CALL]: {
      url: createUrl(`User/ChangeIBAN`),
      method: 'POST',
      body: createBodyLog({ iban: iban.replace(/\s/g, ''), internalNumber }),
      actions: actionSets.CHANGE_IBAN,
    },
  }),
  changeUserCredentials: (userCredentials: UserCredentialsInput) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ResetUserCredentials`),
      body: createBodyLog(userCredentials),
      method: 'POST',
      actions: actionSets.RESET_LOGIN,
      authenticated: true,
    },
  }),
  changeUserCredentialsMock: (userCredentials: UserCredentialsInput) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/ResetUserCredentials`),
      body: createBodyLog(userCredentials),
      method: 'POST',
      actions: actionSets.RESET_LOGIN_MOCK,
      responseTime: 2000,
      mockResponse: {
        statusCode: 'R',
        message: null,
      },
    },
  }),
};

export default auth;
