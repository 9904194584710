import { fontWeight } from '@dovera/design-system';

export default {
  '@font-face': [
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-Light.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-Light.otf) format('opentype')`,
      fontWeight: 100,
    },
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-Regular.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-Regular.otf) format('opentype')`,
      fontWeight: fontWeight.normal,
    },
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-Medium.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-Medium.otf) format('opentype')`,
      fontWeight: fontWeight.semiBold,
    },
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-Bold.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-Bold.otf) format('opentype')`,
      fontWeight: fontWeight.bold,
    },
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-LightItalic.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-LightItalic.otf) format('opentype')`,
      fontStyle: 'italic',
      fontWeight: 100,
    },
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-RegularItalic.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-RegularItalic.otf) format('opentype')`,
      fontStyle: 'italic',
      fontWeight: fontWeight.normal,
    },
    {
      fontFamily: 'DoveraSansNew',
      src: `url("${window.env.config.baseURI}/fonts/dovera-sans/DoveraSansNew-MediumItalic.woff2") format("woff2"),
        url(/fonts/dovera-sans/DoveraSansNew-MediumItalic.otf) format('opentype')`,
      fontStyle: 'italic',
      fontWeight: fontWeight.semiBold,
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-Reg..woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-Reg..woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-Reg..ttf) format('truetype');`,
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-RegIta.woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-RegIta.woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-RegIta.ttf) format('truetype');`,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-Bld.woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-Bld.woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-Bld.ttf) format('truetype');`,
      fontWeight: fontWeight.bold,
    },
    {
      fontFamily: 'Amalia',
      src: `url("${window.env.config.baseURI}/fonts/amalia/AmaliaStd-BldIta.woff2") format("woff2"),
        url(/fonts/amalia/AmaliaStd-BldIta.woff) format('woff'),
        url(/fonts/amalia/AmaliaStd-BldIta.ttf) format('truetype');`,
      fontWeight: fontWeight.bold,
      fontStyle: 'italic',
    },
    {
      fontFamily: 'SourceSansPro',
      src: `url("${window.env.config.baseURI}/fonts/source-sans-pro/SourceSansPro-Regular.woff2") format("woff2")`,
    },
    {
      fontFamily: 'SourceSansPro',
      src: `url("${window.env.config.baseURI}/fonts/source-sans-pro/SourceSansPro-Semibold.woff2") format("woff2")`,
      fontWeight: fontWeight.bold,
    },
  ],
};
