import { useEffect, useRef, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

export const getAuthFromLocalStorage = () => {
  const localStorageAuth = localStorage.getItem('auth');
  const localStorageToken = localStorageAuth
    ? JSON.parse(localStorageAuth)
    : null;

  return localStorageToken;
};

export const revokeToken = async () => {
  const token = getAuthFromLocalStorage()?.token;
  const clientId = window.env.authConfig.client_id;
  const url = window.env?.authConfig?.authority;

  const headers = {
    'Content-Type': 'application/json',
    'X-API-key': clientId,
    Authorization: `Bearer ${token}`,
  };

  const body = {
    token,
    identity_id: clientId,
  };

  await fetch(`${url}/logout`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });
};

export const useTokenExpiration = (token: string) => {
  const [expiredToken, setExpiredToken] = useState(false);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode<any>(token);
      const expirationTimestamp = decodedToken?.exp * 1000;

      intervalRef.current = window.setInterval(() => {
        if (expirationTimestamp < Date.now()) {
          setExpiredToken(true);
        }
      }, 1000);
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [token]);

  return expiredToken;
};

export const isTokenExpiring = (): boolean => {
  const token = getAuthFromLocalStorage()?.token;
  if (!token) return false;
  const timeBeforeExpired = 1 * 60 * 1000; // 1 minute
  // @ts-ignore
  const decodedToken = jwtDecode<any>(token);
  const expirationTimestamp = decodedToken
    ? Number(decodedToken.exp) * 1000
    : 0;
  return expirationTimestamp < Date.now() + timeBeforeExpired;
};

export default useTokenExpiration;
