import {
  isMobile,
  isAndroid,
  isIOS,
  isMacOs,
  isDesktop,
  isWinPhone,
} from 'react-device-detect';

export const isAllowedDevice = isAndroid || isIOS || isWinPhone;

export const isBrowserDevice =
  isDesktop && !(isMacOs && window.innerWidth < 1200);

export const isMobileDevice = isMobile || (isMacOs && window.innerWidth < 1200);
