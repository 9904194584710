import illustrations from '../illustrations';
import links from './links';

export default {
  modalHeader: 'Všetky benefity',
  sideMenu: {
    benefits: {
      title: 'Benefity',
      route: 'benefity',
    },
    doctors: {
      title: 'Lekári',
      route: links.doveraOveritLekara,
    },
    branches: {
      title: 'Pobočky',
      route: links.doveraPobocky,
    },
    porovnanie: {
      title: 'Porovnanie',
      route: 'porovnanie',
    },
  },
  viewMoreButton: 'Zobraziť ďalšie výhody',
  hideMoreButton: 'Skryť výhody',
  categories: {
    vseobecne: {
      id: 'vseobecne',
      title: 'Všeobecné',
      illustration: illustrations.tab_vseobecne,
      benefits: {
        benefit: {
          illustration: '',
          title: 'Príspevok 150€ ročne na zuby',
          data: [
            'na zubára aj dentálnu hygienu',
            'jednoducho vybavíte z mobilu',
          ],
          badges: ['senior', 'rodina'],
          isNew: true,
        },
      },
    },
    rodina: {
      id: 'rodina',
      title: 'Rodina',
      illustration: illustrations.tab_rodina,
      benefits: {
        benefit: {
          illustration: '',
          title: 'Zľava na poistenie od Generali',
          data: ['cestovné poistenie', 'poistenie do hôr'],
          badges: ['mladý', 'rodina'],
          isNew: false,
        },
      },
    },
    babatko: {
      id: 'babatko',
      title: 'Bábätko',
      illustration: illustrations.tab_babatko,
      benefits: {
        benefit: {
          illustration: '',
          title: 'Vrátenie 200+200 za lieky',
          data: [
            'príspevok na najčastejšie ošetrenia a dentálnu hygienu, za ktoré u zubára doplácate',
          ],
          badges: ['mladý', 'chorý'],
          isNew: true,
        },
      },
    },
    chory: {
      id: 'chory',
      title: 'Chorý',
      illustration: illustrations.tab_chory,
      benefits: {
        benefit: {
          illustration: '',
          title: 'Bojovníci za zdravie',
          data: [
            'preplatenie zdravotnej starostlivosti na nepredvídateľné diagnózy 2x do roka',
          ],
          badges: ['chorý'],
          isNew: false,
        },
      },
    },
    mlady: {
      id: 'mlady',
      title: 'Mladý',
      illustration: illustrations.tab_mlady,
      benefits: {
        benefit: {
          illustration: '',
          title: 'Príspevok 100€ ročne na zuby',
          data: [
            'na zubára aj dentálnu hygienu',
            'jednoducho vybavíte z mobilu',
          ],
          badges: ['senior', 'rodina'],
          isNew: true,
        },
      },
    },
    senior: {
      id: 'senior',
      title: 'Senior',
      illustration: illustrations.tab_senior,
      benefits: {
        benefit: {
          illustration: '',
          title: 'Nadštandardné izby za polovicu',
          data: [
            'zľava až 50 % na nadštandardné izby v sieti nemocníc Svet zdravia',
          ],
          badges: ['senior', 'rodina'],
          isNew: false,
        },
      },
    },
  },
};
