import { Log, UserManager, User } from 'oidc-client-dzp';
import { revokeToken } from '../utils/authUtils';
import routes from '../../routers/routes';

export class AuthService {
  public userManager: UserManager;

  constructor() {
    const config = {
      ...window.env.authConfig,
    };

    this.userManager = new UserManager(config);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public getUserManager(): UserManager {
    return this.userManager;
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logOut(onTimeout: boolean = false): Promise<void> {
    return revokeToken()
      .then(() => {
        this.userManager.removeUser();
        window.localStorage.removeItem('auth');
        onTimeout
          ? window.location.replace(routes.loggedOut)
          : window.location.replace(routes.prihlasenie); // logout page dorobit
      })
      .catch(() => {
        this.userManager.removeUser();
        window.localStorage.removeItem('auth');
      });
  }
}
