import * as actionSets from './actionSets';
import { createUrl, createBodyLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { TerminateContractDownload } from '../types/contract';

const controller = 'Contract';

const contract = {
  verifyCode: verifyData => ({
    [API_CALL]: {
      url: createUrl(`${controller}/VerifyCode`),
      body: createBodyLog(verifyData),
      method: 'POST',
      actions: actionSets.VALIDATE_VERIFY_CODE,
      authenticated: true,
    },
  }),
  saveContract: contractData => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SaveContract`),
      body: createBodyLog(contractData),
      method: 'POST',
      actions: actionSets.SAVE_CONTRACT,
      authenticated: true,
    },
  }),
  resignContract: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ReSignedContract`),
      body: createBodyLog(contractToken),
      method: 'POST',
      actions: actionSets.RESIGN_CONTRACT,
      authenticated: true,
    },
  }),
  confirmSignedContract: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/ConfirmSignedContract`),
      body: createBodyLog(contractToken),
      method: 'POST',
      actions: actionSets.CONFIRM_SIGNED_CONTRACT,
      authenticated: true,
    },
  }),
  cancelSignedContract: contractToken => ({
    [API_CALL]: {
      url: createUrl(`${controller}/CancelSignedContract`),
      body: createBodyLog(contractToken),
      method: 'POST',
      actions: actionSets.EMPTY_ACTION,
      authenticated: true,
    },
  }),
  getContractsList: contractListData => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetListContracts`),
      body: createBodyLog(contractListData),
      method: 'POST',
      actions: actionSets.GET_CONTRACTS_LIST,
      authenticated: true,
    },
  }),
  saveUserContact: userContact => ({
    [API_CALL]: {
      url: createUrl(`${controller}/SaveUserContact`),
      body: createBodyLog(userContact),
      method: 'POST',
      actions: actionSets.SAVE_USER_CONTACT,
      authenticated: true,
    },
  }),
  downloadContractDocument: (contractToken, documentToken) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/DownloadContract`),
      body: createBodyLog({ contractToken, documentToken }),
      method: 'POST',
      actions: actionSets.DOWNLOAD_CONTRACT_DOCUMENT,
      authenticated: true,
      meta: { documentToken },
    },
  }),
  terminateContractDocumentAndDownload: (data: TerminateContractDownload) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/TerminateContract`),
      body: createBodyLog(data),
      method: 'POST',
      actions: actionSets.DOWNLOAD_TERMINATED_CONTRACT_DOCUMENT,
    },
  }),
  checkMobile: reqObj => ({
    [API_CALL]: {
      url: createUrl(`${controller}/CheckMobile`),
      body: createBodyLog(reqObj),
      method: 'POST',
      actions: actionSets.CHECK_MOBILE,
      authenticated: true,
    },
  }),
  checkEmail: reqObj => ({
    [API_CALL]: {
      url: createUrl(`${controller}/CheckEmail`),
      body: createBodyLog(reqObj),
      method: 'POST',
      actions: actionSets.CHECK_EMAIL,
      authenticated: true,
    },
  }),
  updateContractAddress: data => ({
    [API_CALL]: {
      url: createUrl(`Admin/UpdateContractAddress`),
      method: 'POST',
      body: createBodyLog(data),
      actions: actionSets.UPDATE_CONTRACT_ADDRESS,
    },
  }),
};

export default contract;
