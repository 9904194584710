import { WizardReducer } from '../../../types/wizard.d';
import { PersonType, InsuranceCompany } from '../../../types/common.d';

const mock: WizardReducer = {
  isFromZZ: false,
  step0: {
    undefined: {
      ocr: null,
    },
    insuree: {
      ocr: null,
    },
    legalRepresentative: {
      ocr: null,
    },
    isCompleted: true,
  },
  step1: {
    person: PersonType.child,
    insuranceCompany: InsuranceCompany.vszp,
    nationality: 'SK',
    isCompleted: true,
  },
  step2: {
    insuree: {
      birthNumber: '0458056434',
      dateOfBirth: '2004-08-05T10:00:00.000Z',
      firstName: 'Ferko',
      lastName: 'Dieta',
      birthName: 'Dieta',
      idNumber: null,
      nationality: 'SK',
      nationalityIsEU: null,
      sex: null,
      academicDegreeBeforeName: null,
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: 'KOŠICE-DARGOVSKÝCH HRDINOV',
        cislo: '3',
        psc: '04022',
        ulicaNazov: 'BENADOVA',
        statNazov: 'SLOVENSKÁ REPUBLIKA',
        statKod: 'SK',
        obecKod: '598682',
        ulicaKod: '246625',
      },
      temporaryAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {},
      havePostalAddress: false,
      haveTemporaryAddress: false,
      stayFrom: null,
      stayTo: null,
    },
    legalRepresentative: {
      birthNumber: '9403067927',
      dateOfBirth: '1994-03-05T23:00:00.000Z',
      firstName: 'Janko',
      lastName: 'Rodic',
      birthName: 'Rodic',
      idNumber: 'SL123LS',
      nationality: 'SK',
      nationalityIsEU: null,
      sex: null,
      academicDegreeBeforeName: 'Ing.',
      academicDegreeAfterName: null,
      permanentAddress: {
        obecNazov: 'KOŠICE-DARGOVSKÝCH HRDINOV',
        cislo: '32',
        psc: '04022',
        ulicaNazov: 'BENADOVA',
        statNazov: 'SLOVENSKÁ REPUBLIKA',
        statKod: 'SK',
        obecKod: '598682',
        ulicaKod: '246625',
      },
      temporaryAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      postalAddress: {
        obecNazov: null,
        cislo: null,
        psc: null,
        ulicaNazov: null,
      },
      ocr: {},
      havePostalAddress: false,
      haveTemporaryAddress: false,
      stayFrom: null,
      stayTo: null,
    },
    isCompleted: true,
  },
  step3: {
    insurancePayerType: null,
    employer: null,
    selfEmployed: null,
    state: null,
    stateOther: null,
    retiree: null,
    student: null,
    homeWithChild: null,
    icoSZCO: null,
    nazovSZCO: null,
    isCompleted: false,
  },
  step4: {
    email: null,
    phone: null,
    //  @ts-ignore
    // relation: 'father',
    relation: null,
    motherInsuranceCompany: null,
    motherBirthNumber: null,
    //  @ts-ignore
    // deliveryAddressType: 'permanentAddress',
    deliveryAddressType: null,
    deliveryAddress: null,
    courierAddress: null,
    strongConsent: true,
    weakConsent: true,
    enableToLogin: false,
    isCompleted: false,
    relationship: null,
    deliveryName: null,
  },
  step5: { signType: null, phoneNumberForSMS: null, isCompleted: false },
};

export default mock;
