import links from './links';

export default [
  {
    '16-user': '<strong>30 rokov</strong> na slovenskom trhu',
  },
  {
    users: 'Vyše <strong>1,6 milióna poistencov</strong>',
  },
  {
    map: `Kvalitný servis v <strong>75 pobočkách</strong> na <a href=${links.doveraPobocky}  target="_blank">celom Slovensku</a>`,
  },
  {
    '24-doctor': `<strong>Vyše 21 655</strong> (90 %) <strong>zmluvných lekárov</strong> <a href=${links.doveraOveritLekara} target="_blank">Overiť zazmluvnenosť</a>`,
  },
  {
    check: 'Vyplnenie prihlášky do <strong>3 minút</strong>',
  },
  {
    '24-home-care':
      'Viac ako <strong>22 tisíc zazmluvnených ambulancií</strong>',
  },
  {
    '24-award':
      '<strong>Prestížne ocenenia</strong> - Smart poisťovňa, IT projekt roka, FB Awards, ITAPA',
  },
  {
    pill:
      '<strong>takmer 20 miliónov eur</strong> v doplatkoch za lieky 300+300',
  },
  {
    '24-health':
      '<strong>takmer 1,3 milióna eur</strong> v programe Bojovníci za zdravie',
  },
  {
    percentage: 'Zľavy obchodných partnerov <strong>až do 50&nbsp;%</strong>',
  },
  {
    smartphone: '<strong>Najmodernejšia</strong> mobilná aplikácia',
  },
  {
    '24-briefcase': 'Zmenu ZP stačí <strong>nahlásiť zamestnávateľovi</strong>',
  },
];
