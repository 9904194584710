import {
  InsureeType,
  Address,
  PersonType,
  InsurancePayer,
  WhereToDelivery,
  SelfEmployedCode,
  SignType,
  RelationType,
  SexType,
  Employer,
  SelfEmployedCodeToType,
} from '../types/common.d';

import {
  CourierAddress,
  Contact,
  RegistrationDocumentDto,
  TypDokumentuKod,
} from '../types/registration.d';
import { Nullable } from '../types/index.d';

import defaultStore from '../store';
import {
  NewBornChildsMomIsInDovera,
  NewBornChildsMomIsInVSZP,
  NewBornChildsMomIsInUnion,
  PersonNewBornIsForeigner,
  PersonChildChangingInsuranceCompanyFromVSZP,
  PersonChildChangingInsuranceCompanyFromUZP,
  ChildReturnWithinEU,
  ChildReturnOutsideOfEU,
  PersonNewBornIsForeignerHasNoInsurance,
  PersonChildIsForeignerChangingInsuranceCompanyFromVSZP,
  PersonChildIsForeignerChangingInsuranceCompanyFromUZP,
  AdultReturnWithinEU,
  AdultReturnOutsideOfEU,
  AdultChangingInsuranceCompanyFromVSZP,
  AdultChangingInsuranceCompanyFromUZP,
  PersonAdultIsForeignerHasNoInsurance,
  PersonAdultIsForeignerChangingInsuranceCompanyFromVSZP,
  PersonAdultIsForeignerChangingInsuranceCompanyFromUZP,
  PayerIsStateDueStudenForeign,
  PayerIsStateDueRetireeOnOldAgePension,
  PayerIsStateDueRetireeEarlyPension,
  PayerIsStateDueRetireeDisabilityPension,
  PayerIsStateDueRetireeExtendedPension,
  PayerIsStateDueRetireeInvalidityServicePension,
  PayerIsStateDueRetireeForeignPension,
  PayerIsStateDueUnemploying,
  PayerIsStateDueHomeWithChildParentalLeave,
  PayerIsStateDueHomeWithChildUpTo6,
  PayerIsStateDueOtherB21,
  PayerIsStateDueMateriaNeed,
  PayerIsStateDueOtherB23,
  PayerIsStateDueOtherB24,
  PayerIsStateDueOtherB25,
  PayerIsStateDueOtherB26,
  PayerIsStateDueOtherB27,
  PayerIsStateDueOtherB28,
  PayerIsStateDueOtherB29,
  PayerIsStateDueOtherB30,
  PayerIsStateDueOtherB31,
  PayerIsStateDueOtherB32,
  PayerIsStateDueStudenDoktorand,
  PayerIsStateDueStudenExternal,
  PayerIsStateDueOtherB38,
  PayerIsStateDueOtherB39,
  PayerIsEmployer,
  PayerIsSelfEmployed,
  PayerIsStateDueHomeWithChildMaternityLeave,
  PayerIsStateDueStudenDaily,
  PayerIsSelfPayer,
  PayerIsStateDueOther,
} from './registrationHelpers';
import {
  getDateFromBirthNumberToString,
  getCorrectYearForActiveSession,
} from './dateUtils';
import {
  slovakStateCode,
  POVINNY_ZAMESTNAVATEL,
  PROHIBITED_NEWBORN,
  PROHIBITED_REGISTRATION,
} from '../constants';
import moment from 'moment';
import strings from '../constants/strings';
import { hasChangePermission } from './userRolesUtils';

export const isSlovak = (stateCode: string): boolean =>
  stateCode === slovakStateCode;

export const podavatelCisloDokladu = (isZZ: boolean): string =>
  //  @ts-ignore
  defaultStore.getState().wizard.step2[
    isZZ ? InsureeType.legalRepresentative : InsureeType.insuree
  ]?.idNumber;

export const isPlatitelPoistnehoPovinny = () => {
  let isRequired = true;
  const { person } = defaultStore.getState().wizard.step1;
  const { insuree } = defaultStore.getState().wizard.step2;

  const isChild = person === PersonType.child || person === PersonType.newBorn;

  if (isChild && insuree && insuree?.birthNumber) {
    if (
      NewBornChildsMomIsInDovera() ||
      NewBornChildsMomIsInVSZP() ||
      NewBornChildsMomIsInUnion() ||
      PersonNewBornIsForeigner() ||
      ((PersonChildChangingInsuranceCompanyFromVSZP() ||
        PersonChildChangingInsuranceCompanyFromUZP() ||
        ChildReturnWithinEU() ||
        ChildReturnOutsideOfEU() ||
        PersonNewBornIsForeignerHasNoInsurance() ||
        PersonChildIsForeignerChangingInsuranceCompanyFromVSZP() ||
        PersonChildIsForeignerChangingInsuranceCompanyFromUZP()) &&
        !isDietaSkStarsie16(insuree?.birthNumber))
    ) {
      isRequired = false;
    }
  }

  return isRequired;
};

export const isDietaSkStarsie16 = rodneCislo => {
  // toto nebude fungovat pre starsich ako 100 rokov
  const now = new Date();
  let bornYear = parseInt(rodneCislo.substr(0, 2), 10);
  const bornMonthFemale = parseInt(rodneCislo.substr(2, 1), 10);
  const bornMonth = parseInt(rodneCislo.substr(2, 2), 10);
  const bornDay = parseInt(rodneCislo.substr(4, 2), 10);
  bornYear += bornYear <= now.getFullYear() - 2000 ? 2000 : 1900;
  const born = new Date(
    bornYear,
    bornMonthFemale > 5 ? bornMonth - 50 : bornMonth,
    bornDay,
  );
  const age = moment(now).diff(moment(born), 'years');

  return age >= POVINNY_ZAMESTNAVATEL;
};

export const kodPredchadzajucaZP = (): Nullable<string> => {
  let kod: Nullable<string> = null;
  const { person } = defaultStore.getState().wizard.step1;

  // 0 - Novorodenec
  // 3 - Chcem sa prepoistiť z poisťovne Všeobecná ZP
  // 6 - Chcem sa prepoistiť z poisťovne Union ZP
  // 1 - Vrátil som sa z cudziny v rámci EÚ
  // 2 - Vrátil som sa z cudziny mimo EÚ
  // 7 - Som cudzinec a nie som poistencom inej ZP v rámci SR
  // 8 - Som cudzinec a som poistencom Všeobecná ZP
  // 11 - Som cudzinec a som poistencom Union ZP"

  if (PersonNewBornIsForeigner() || person === PersonType.newBorn) {
    kod = '0';
  } else if (AdultReturnWithinEU() || ChildReturnWithinEU()) {
    kod = '1';
  } else if (AdultReturnOutsideOfEU() || ChildReturnOutsideOfEU()) {
    kod = '2';
  } else if (
    AdultChangingInsuranceCompanyFromVSZP() ||
    PersonChildChangingInsuranceCompanyFromVSZP()
  ) {
    kod = '3';
  } else if (
    AdultChangingInsuranceCompanyFromUZP() ||
    PersonChildChangingInsuranceCompanyFromUZP()
  ) {
    kod = '6';
  } else if (
    PersonAdultIsForeignerHasNoInsurance() ||
    PersonNewBornIsForeignerHasNoInsurance()
  ) {
    kod = '7';
  } else if (
    PersonAdultIsForeignerChangingInsuranceCompanyFromVSZP() ||
    PersonChildIsForeignerChangingInsuranceCompanyFromVSZP()
  ) {
    kod = '8';
  } else if (
    PersonAdultIsForeignerChangingInsuranceCompanyFromUZP() ||
    PersonChildIsForeignerChangingInsuranceCompanyFromUZP()
  ) {
    kod = '11';
  }

  return kod;
};

export const kodTypKategorie = (): string => {
  if (isPlatitelPoistnehoPovinny() === false) {
    return `01`;
  }
  if (PayerIsStateDueStudenForeign()) {
    return `02`;
  }
  if (PayerIsStateDueRetireeOnOldAgePension()) {
    return `03_1`;
  }
  if (PayerIsStateDueRetireeEarlyPension()) {
    return `03_2`;
  }
  if (PayerIsStateDueRetireeDisabilityPension()) {
    return `03_3`;
  }
  if (PayerIsStateDueRetireeExtendedPension()) {
    return `03_5`;
  }
  if (PayerIsStateDueRetireeInvalidityServicePension()) {
    return `03_6`;
  }
  if (PayerIsStateDueRetireeForeignPension()) {
    return `03_10`;
  }
  if (PayerIsStateDueUnemploying()) {
    return `05`;
  }
  if (PayerIsStateDueHomeWithChildParentalLeave()) {
    return `06`;
  }
  if (PayerIsStateDueHomeWithChildUpTo6()) {
    return `07`;
  }
  if (PayerIsStateDueOtherB21()) {
    return `08`;
  }
  if (PayerIsStateDueMateriaNeed()) {
    return `10`;
  }
  if (PayerIsStateDueOtherB23()) {
    return `12`;
  }
  if (PayerIsStateDueOtherB24()) {
    return `13`;
  }
  if (PayerIsStateDueOtherB25()) {
    return `14`;
  }
  if (PayerIsStateDueOtherB26()) {
    return `15`;
  }
  if (PayerIsStateDueOtherB27()) {
    return `16`;
  }
  if (PayerIsStateDueOtherB28()) {
    return `18`;
  }
  if (PayerIsStateDueOtherB29()) {
    return `21`;
  }
  if (PayerIsStateDueOtherB30()) {
    return `22`;
  }
  if (PayerIsStateDueOtherB31()) {
    return `23`;
  }
  if (PayerIsStateDueOtherB32()) {
    return `24`;
  }
  if (PayerIsStateDueStudenDoktorand()) {
    return `27`;
  }
  if (PayerIsStateDueStudenExternal()) {
    return `53`;
  }
  if (PayerIsStateDueOtherB38()) {
    return `25`;
  }
  if (PayerIsStateDueOtherB39()) {
    return `09`;
  }
  return '';
};

export const kodTypKategorieToLabel = (kod: string) => {
  const studentCodes = ['01', '27', '53', '02'];
  const parentalLeaveCodes = ['06', '07'];
  // TODO: what if '01'

  if (studentCodes.includes(kod)) return 'student';
  if (parentalLeaveCodes.includes(kod)) return 'homeWithChild';
  if (kod === '03') return 'retiree';
  if (kod === '05') return 'unemployed';
  if (kod === '10') return 'helping';
  return 'other';
};

export const kodPlatitelaPoistneho = (): string => {
  if (PayerIsEmployer()) {
    return '1';
  }
  if (PayerIsSelfEmployed()) {
    return '2';
  }
  if (PayerIsStateDueHomeWithChildMaternityLeave()) {
    return `3`;
  }
  if (PayerIsStateDueStudenDaily()) {
    return `4`;
  }
  if (PayerIsStateDueStudenDoktorand()) {
    return `5`;
  }
  if (PayerIsSelfPayer()) {
    return `6`;
  }
  if (PayerIsStateDueUnemploying()) {
    return `7`;
  }
  if (PayerIsStateDueRetireeOnOldAgePension()) {
    return `8`;
  }
  if (PayerIsStateDueRetireeEarlyPension()) {
    return `9`;
  }
  if (PayerIsStateDueRetireeDisabilityPension()) {
    return `10`;
  }
  if (PayerIsStateDueRetireeExtendedPension()) {
    return `11`;
  }
  if (PayerIsStateDueOther()) {
    return `12`;
  }
  return '12';
};

export const kodDruhDochodku = (): Nullable<string> => {
  const typKategorie = kodTypKategorie();

  if (typKategorie && typKategorie.substring(0, 3) === '03_') {
    const typKategoriePodmnozina = typKategorie.split('03_');
    return typKategoriePodmnozina[1] !== '' ? typKategoriePodmnozina[1] : null;
  }

  return null;
};
export const matkaPoistovna = (): Nullable<string> => {
  if (NewBornChildsMomIsInDovera()) {
    return `24`;
  }
  if (NewBornChildsMomIsInVSZP()) {
    return `25`;
  }
  if (NewBornChildsMomIsInUnion()) {
    return `27`;
  }
  return null;
};
export const sposobPrevzatiaNarPreukazu = (): string => {
  const { deliveryAddressType } = defaultStore.getState().wizard.step4;
  let typ;

  if (deliveryAddressType === WhereToDelivery.permanentAddress) {
    typ = 'P';
  } else if (deliveryAddressType === WhereToDelivery.legalAddress) {
    typ = 'R';
  } else if (deliveryAddressType === WhereToDelivery.postalAddress) {
    typ = 'K1';
  } else if (deliveryAddressType === WhereToDelivery.temporaryAddress) {
    typ = 'K2';
  } else if (deliveryAddressType === WhereToDelivery.branchOffice) {
    typ = 'O';
  } else {
    typ = 'R';
  }

  return typ;
};

export const dajTypPoistencaRecap = rblPrihlaskaZa => {
  if (rblPrihlaskaZa === '0' || rblPrihlaskaZa === '2') return 'adult';
  if (
    rblPrihlaskaZa === '1' ||
    rblPrihlaskaZa === '3' ||
    rblPrihlaskaZa === '4'
  )
    return 'child';
  return '';
};

export const sposobDoruceniaPreukazuRecap = (
  preukazDorucitNa: number,
  rodic?: any,
  adresaRodic?: any,
): string => {
  let typ;

  if (preukazDorucitNa === 0) {
    // delivery type 'P'
    typ = WhereToDelivery.permanentAddress;
  } else if (preukazDorucitNa === 3) {
    // delivery type 'K1'
    typ = WhereToDelivery.postalAddress;
  } else if (preukazDorucitNa === 1) {
    // delivery type 'K2'
    typ = WhereToDelivery.temporaryAddress;
  } else if (preukazDorucitNa === 4) {
    // delivery type 'O'
    typ = WhereToDelivery.branchOffice;
  } else if (preukazDorucitNa === 2) {
    // delivery type 'R'
    typ = WhereToDelivery.legalAddress;
  } else {
    // default delivery type 'R'
    typ = 'R';
  }

  return typ;
};

export const sposobDoruceniaNaPodpis = (): number | null => {
  const { signType } = defaultStore.getState().wizard.step5;
  // Ocakavane hodnoty su:
  // 1 - prihlasku vytlaci, podpise a posle postou do DZP
  // 3 - prihlasku doruci kurier a po podpisani zoberie naspat
  // 4 - prihlasku podpise kvalifikovanym elektronickym podpisom
  // 5 - prihlasku podpise digitalnym podpisom
  // 6 - prihlasku podpise cez sms
  // Ak su vo formulari dalsie moznosti, dajte nam prosim vediet, v aktualnej prihlaske ine nepouzivame.
  switch (signType) {
    case SignType.print:
      return 1;
    case SignType.courier:
      return 3;
    case SignType.digital:
      return 5;
    case SignType.sms:
      return 6;
    default:
      return null;
  }
};

export const kodPobockaPrevz = (): Nullable<number> => {
  const {
    deliveryAddressType,
    deliveryAddress,
  } = defaultStore.getState().wizard.step4;
  return deliveryAddressType === WhereToDelivery.branchOffice
    ? deliveryAddress
    : null;
};

export const kodTypPodnikania = (): Nullable<string> => {
  const {
    insurancePayerType,
    selfEmployed,
  } = defaultStore.getState().wizard.step3;
  if (insurancePayerType === InsurancePayer.selfEmployed) {
    return selfEmployed && SelfEmployedCode[selfEmployed];
  }

  return null;
};

export const typPodnikatelaZKodu = (kodPodnikatela: string) => {
  return (
    strings.applicationForm.step3.content.form[
      SelfEmployedCodeToType[kodPodnikatela]
    ] || ''
  );
};

export const kodSkupinaKategorie = (): Nullable<string> => {
  if (PayerIsEmployer()) {
    return 'SPP';
  }
  return null;
};

export const suhlasSoSpracovanimOsUdajov = (): boolean => {
  const { strongConsent } = defaultStore.getState().wizard.step4;
  return !!strongConsent;
};
export const NamietkaKontaktneUdaje = (): boolean => {
  const { weakConsent } = defaultStore.getState().wizard.step4;
  return !!weakConsent;
};

export const trimString = (string: string): string =>
  string.trim().replace(/\s+/g, '');

export const getValidPhone = (phone: string): string => {
  const phoneNumber = phone ? trimString(phone) : '';
  if (phoneNumber.startsWith('+421')) return phoneNumber;
  if (phoneNumber.startsWith('00421')) {
    return phoneNumber.replace('00421', '+421');
  }
  if (phoneNumber.startsWith('421')) {
    return `+${phoneNumber}`;
  }
  if (phoneNumber.startsWith('09')) return `+421${phoneNumber.slice(1, 10)}`;
  if (phoneNumber.startsWith('9')) return `+421${phoneNumber}`;
  return phoneNumber;
};

export const dajCisloPreSMS = (): string | null => {
  const { signType, phoneNumberForSMS } = defaultStore.getState().wizard.step5;

  if (!signType || signType !== SignType.sms || !phoneNumberForSMS) return null;
  return getValidPhone(phoneNumberForSMS);
};

export const dajUdajePreKuriera = (): CourierAddress | null => {
  const { courierAddress } = defaultStore.getState().wizard.step4;

  const { signType } = defaultStore.getState().wizard.step5;

  if (!signType || signType !== SignType.courier) return null;
  const courierData = {
    menoPrijimatela: courierAddress?.firstName?.toString().trim() || '',
    priezviskoPrijimatela: courierAddress?.lastName?.toString().trim() || '',
    telefonickyKontakt: getValidPhone(
      courierAddress?.phone?.toString().trim() ?? '',
    ),
    adresaDorucenia: {
      obecNazov: courierAddress?.town?.trim(),
      ulicaNazov: courierAddress?.street?.trim(),
      cislo: courierAddress?.number?.trim(),
      psc: courierAddress?.zip?.trim(),
    },
  };
  return courierData;
};

export const kodTypZakonnehoZastupcu = (): string => {
  const { relation } = defaultStore.getState().wizard.step4;
  switch (relation) {
    case RelationType.mother:
      return 'M';
    case RelationType.father:
      return 'O';
    default:
      return 'I';
  }
};

export const datumVznikuPoistenia = (): Nullable<string> => {
  const { birthNumber } = defaultStore.getState().wizard.step2.insuree;
  // mama je poistena v dovere
  if (NewBornChildsMomIsInDovera() && birthNumber) {
    // datum narodenia bud z datumu narodenia alebo z rodneho cisla
    return getDateFromBirthNumberToString(birthNumber.toString());
  }
  // mama nie je poistena v dovere
  // dátum podania prihlášky
  return new Date().toISOString();
};

export const szcoIcoAndName = (): { icoSZCO: string; nazovSZCO: string } => {
  const icoSZCO = 'null';
  const nazovSZCO = 'null';
  // if (
  //     this.prihlaska.get(Prihlaska.KEY_PLATITEL_POISTENIA) === Prihlaska.VALUE_PLATITEL_POISTENIA_SZCO
  //     && this.prihlaska.get(Prihlaska.KEY_TYP_SZCO) === Prihlaska.VALUE_TYP_SZCO__ZIVONSTNIK
  // ) {
  //     icoSZCO = this.prihlaska.get(Prihlaska.KEY_ZIVNOSTNIK_ICO);
  //     nazovSZCO = this.prihlaska.get(Prihlaska.KEY_ZIVNOSTNIK_MENO);
  // }
  return {
    icoSZCO,
    nazovSZCO,
  };
};

export const szcoIco = (): Nullable<string> => {
  const { icoSZCO } = defaultStore.getState().wizard.step3;
  return icoSZCO?.trim();
};
export const szcoName = (): Nullable<string> => {
  const { nazovSZCO } = defaultStore.getState().wizard.step3;
  return nazovSZCO?.trim();
};

export const isObtainedOCR = () => {
  // const {
  //   insuree: { ocr: insureeOCR },
  //   legalRepresentative: { ocr: legalRepresentativeOCR },
  // } = defaultStore.getState().wizard.step2;

  // const insuree = Object.values(insureeOCR).find(key => key === true);
  // const legalRepresentative = Object.values(legalRepresentativeOCR).find(
  //   key => key === true,
  // );

  // if (insuree || legalRepresentative) return true;

  if (
    !!defaultStore.getState().ocr?.nfinity_ocr?.transactionData?.data
      ?.birthNumber
  ) {
    return true;
  }

  return false;
};

export const prilohy = (): Nullable<RegistrationDocumentDto[]> => {
  const out = defaultStore.getState().registration.registration.prilohy;
  if (!out) {
    return null;
  }
  return out.length ? out : null;
};

export const getFrontAndBackSideOP = (): Nullable<{
  predna: string;
  zadna: string;
}> => {
  const op = defaultStore.getState().ocr.nfinity_ocr.transactionData.data
    ?.verificationDocument;
  if (op && op.front && op.back) {
    return { predna: op.front, zadna: op.back };
  }
  return null;
};

export const getWizrdValue = (obj, key): undefined | string => {
  if (!obj || !key) return undefined;
  if (!key) return obj.toString().trim();
  if (!obj[key]) return undefined;
  // @ts-ignore
  if (obj[key].hasOwnProperty('value')) return obj[key].value.toString().trim();
  return obj[key].toString().trim();
};
export const getAddress = (personAddress): Address => {
  const address: Address = {
    id: 0,
    ulicaKod: '',
    ulicaNazov: '',
    cislo: '',
    obecKod: '',
    obecNazov: '',
    psc: '',
    lokalitaId: '',
    lokalitaNazov: '',
    statKod: '',
    statNazov: '',
  };
  if (!personAddress) return address;
  address.ulicaKod = getWizrdValue(personAddress, 'ulicaKod');
  address.ulicaNazov = getWizrdValue(personAddress, 'ulicaNazov');
  address.cislo = getWizrdValue(personAddress, 'cislo');
  address.obecKod = getWizrdValue(personAddress, 'obecKod');
  address.obecNazov =
    getWizrdValue(personAddress, 'obecNazov') ??
    getWizrdValue(personAddress, 'obecText');
  address.psc = getWizrdValue(personAddress, 'psc');
  address.lokalitaId = getWizrdValue(personAddress, 'lokalitaId');
  address.lokalitaNazov = getWizrdValue(personAddress, 'lokalitaNazov');
  address.statKod = getWizrdValue(personAddress, 'statKod');
  address.statNazov = getWizrdValue(personAddress, 'statNazov');
  return address;
};

/**
 *
 * Poistenect - Kontaktne Udaje
 *
 */
export const getContactData = (wizardData: any): Contact[] => {
  const contats: Contact[] = [];
  contats.push({
    id: null,
    typ: 'email',
    kontakt: wizardData.email ? wizardData.email.trim() : '',
  });
  contats.push({
    id: null,
    typ: 'mobil',
    kontakt: wizardData.phone ? getValidPhone(wizardData.phone) : '',
  });
  return contats;
};

export const getSexFromBirthNumber = (
  birthNumber: Nullable<string>,
): SexType | undefined => {
  if (!birthNumber || birthNumber === '') return undefined;
  return parseInt(birthNumber.substring(2, 3), 10) >= 5
    ? SexType.female
    : SexType.male;
};

export const getEmployerId = (): string => {
  const userObj = defaultStore.getState().auth.user;
  return userObj?.employeerInternalNumber || '';
  // options: 8181142001 || 0000507741
};

export const zamestnavatelUdaje = (): Nullable<Employer> => {
  const { insurancePayerType, employer } = defaultStore.getState().wizard.step3;
  if (!insurancePayerType) {
    return null;
  }
  //
  return employer;
};

export const datumVznikuSkutocnostiPreVznikPoistenia = (): Nullable<string> => {
  const { person } = defaultStore.getState().wizard.step1;

  // plnit pri type Z, 1.1. nasledujuceho roka, pri type vzniku ako datumzvnikupoistenia
  if (person !== PersonType.newBorn) {
    return new Date(getCorrectYearForActiveSession(), 0, 1).toISOString();
  }

  return datumVznikuPoistenia();
};

export const kodTypPribuzenskehoVztahu = (): string | undefined => {
  const { person } = defaultStore.getState().wizard.step1;
  const { nationality } = defaultStore.getState().wizard.step2.insuree;
  if (person !== PersonType.adult && nationality !== slovakStateCode) {
    return 'RD';
  }
  return undefined;
};

export const isNewbornProhibitedByLeg = () =>
  moment().isAfter(moment(PROHIBITED_NEWBORN));

export const isRegistrationProhibited = roles => {
  const prohibitedDate = moment().isBetween(
    moment(PROHIBITED_REGISTRATION.from),
    moment(PROHIBITED_REGISTRATION.to),
    'hours',
  );
  const hasChanginRole = !hasChangePermission(roles);
  return prohibitedDate && hasChanginRole;
};

export const haveOPDocuments = (files: any[]): boolean => {
  if (files.length === 0) return false;
  // api data
  if (
    files.filter(
      f =>
        f.documentType === TypDokumentuKod.op_predna_strana ||
        f.documentType === TypDokumentuKod.op_zadna_strana,
    ).length === 2
  ) {
    return true;
  }

  // uploaded data
  if (
    files.filter(
      f =>
        f.typDokumentu === TypDokumentuKod.op_predna_strana ||
        f.typDokumentu === TypDokumentuKod.op_zadna_strana,
    ).length === 2 &&
    files.every(a => a.obsah)
  ) {
    return true;
  }
  return false;
};
