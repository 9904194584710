import * as actionSets from './actionSets';
import { createUrl, createBodyLog } from '../utils/apiUtils';
import { API_CALL } from '@mediworx/mdxutils';
import { MOCK_API_CALL } from '../middleware/mockApiMiddleware';

const controller = 'Extranet';
const extranet = {
  getList: (
    employeerInternalNumber,
    production,
    registrationState = null,
    dateFrom,
    dateThru,
  ) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetListRegistrations`),
      body: createBodyLog({
        employeerInternalNumber,
        production,
        registrationState,
        dateFrom,
        dateThru,
      }),
      method: 'POST',
      actions: actionSets.GET_APPLICATION_LIST,
    },
  }),
  getListMock: (
    employeerInternalNumber,
    production,
    registrationState = null,
    dateFrom,
    dateThru,
  ) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/GetListRegistrations`),
      body: createBodyLog({
        employeerInternalNumber,
        production,
        registrationState,
        dateFrom,
        dateThru,
      }),
      method: 'POST',
      actions: actionSets.GET_APPLICATION_LIST,
      responseTime: 2000,
      mockResponse: {
        registrations: [
          {
            id: 279197,
            idTXS: '2155643',
            receiptDate: '2023-07-11T00:00:00Z',
            name: 'SLAVOSLAV',
            surname: 'VASIĽ',
            birthYear: '1981',
            city: 'SOBRANCE',
            price: '90.00',
            confirmedPrice: true,
            type: 'Z',
            state: 'A',
            deniedReason: null,
            internalNumber: '9000000000',
            phoneNumber: null,
          },
          {
            id: 279193,
            idTXS: '2155563',
            receiptDate: '2023-07-04T00:00:00Z',
            name: 'MARCEL',
            surname: 'ONDRÍK',
            birthYear: '1998',
            city: 'ÁBELOVÁ',
            price: '90.00',
            confirmedPrice: true,
            type: 'Z',
            state: 'A',
            deniedReason: null,
            internalNumber: '9000000000',
            phoneNumber: '+421918531350',
          },
          {
            id: 279194,
            idTXS: '2155603',
            receiptDate: '2023-07-04T00:00:00Z',
            name: 'POKUS',
            surname: 'OCR',
            birthYear: '2004',
            city: 'JASENICA',
            price: '90.00',
            confirmedPrice: true,
            type: 'Z',
            state: 'A',
            deniedReason: null,
            internalNumber: '9000000000',
            phoneNumber: '+421918531350',
          },
          {
            id: 279190,
            idTXS: null,
            receiptDate: '2023-07-03T00:00:00Z',
            name: 'CUDZINEC',
            surname: 'DOSPELY',
            birthYear: '1993',
            city: 'BARCA',
            price: '-',
            confirmedPrice: false,
            type: 'Z',
            state: 'V',
            deniedReason: null,
            internalNumber: '9000000000',
            phoneNumber: null,
          },
        ],
        filterData: {
          validFrom: '2023-01-17T00:00:00Z',
          validThru: '2024-01-16T00:00:00Z',
        },
      },
    },
  }),

  getListEmployeeMock: (employeerInternalNumber, dateFrom, dateThru) => ({
    [MOCK_API_CALL]: {
      url: createUrl(`${controller}/GetListEmployee`),
      body: createBodyLog({
        employeerInternalNumber,
        dateFrom,
        dateThru,
      }),
      method: 'POST',
      actions: actionSets.GET_EMPLOYEE_LIST,
      responseTime: 2000,
      mockResponse: {
        employeeRegistrations: [
          {
            contractFrom: '2023-01-17T00:00:00Z',
            internalNumber: '9084000000',
            name: 'SPOLOČNOSŤ',
            surname: 'SLOVENSKÁ ENERGETICKÁ AGENTÚRA',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-08-02T00:00:00Z',
            internalNumber: '9084000001',
            name: 'PATRIK',
            surname: 'PAČUTA',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-06T00:00:00Z',
            internalNumber: '9084000005',
            name: 'KATARÍNA',
            surname: 'ILLÉŠOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-06T00:00:00Z',
            internalNumber: '9084000006',
            name: 'DENISA',
            surname: 'PELČOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-06T00:00:00Z',
            internalNumber: '9084000007',
            name: 'ALEXANDRA',
            surname: 'KOVÁČOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 84,
          },
          {
            contractFrom: '2023-04-06T00:00:00Z',
            internalNumber: '9084000008',
            name: 'IVANA',
            surname: 'MIGASOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 41,
          },
          {
            contractFrom: '2023-04-12T00:00:00Z',
            internalNumber: '9084000009',
            name: 'BIANKA',
            surname: 'OLÁHOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 115,
          },
          {
            contractFrom: '2023-04-12T00:00:00Z',
            internalNumber: '9084000010',
            name: 'ERIKA',
            surname: 'APRÓOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 56,
          },
          {
            contractFrom: '2023-04-12T00:00:00Z',
            internalNumber: '9084000011',
            name: 'MICHAELA',
            surname: 'BERKYOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-12T00:00:00Z',
            internalNumber: '9084000012',
            name: 'BEATA',
            surname: 'PEŠANOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-15T00:00:00Z',
            internalNumber: '9084000018',
            name: 'MARTINA',
            surname: 'DEMČÁKOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-15T00:00:00Z',
            internalNumber: '9084000020',
            name: 'KATARÍNA',
            surname: 'HORÍNKOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-04-27T00:00:00Z',
            internalNumber: '9084000021',
            name: 'DENISA',
            surname: 'OLÁHOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-05-04T00:00:00Z',
            internalNumber: '9084000025',
            name: 'MONIKA',
            surname: 'BRYNDZOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-05-11T00:00:00Z',
            internalNumber: '9084000026',
            name: 'JANA',
            surname: 'JURKOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-05-25T00:00:00Z',
            internalNumber: '9084000031',
            name: 'ERIKA',
            surname: 'OLÁHOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 56,
          },
          {
            contractFrom: '2023-05-25T00:00:00Z',
            internalNumber: '9084000032',
            name: 'MARTINA',
            surname: 'TATAI',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-05-30T00:00:00Z',
            internalNumber: '9084000033',
            name: 'JANA',
            surname: 'NEMCOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-06-02T00:00:00Z',
            internalNumber: '9084000034',
            name: 'ERIKA',
            surname: 'BOGDÁNYOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 68,
          },
          {
            contractFrom: '2023-07-04T00:00:00Z',
            internalNumber: '9084000037',
            name: 'BRIGITA',
            surname: 'TRIZNOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-07-05T00:00:00Z',
            internalNumber: '9084000038',
            name: 'PATRIK',
            surname: 'GALDÍK',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 0,
          },
          {
            contractFrom: '2023-07-27T00:00:00Z',
            internalNumber: '9084000040',
            name: 'MARTINA',
            surname: 'ŽIVČAKOVÁ MRKVÍKOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 60,
          },
          {
            contractFrom: '2023-07-27T00:00:00Z',
            internalNumber: '9084000041',
            name: 'NIKOLETA',
            surname: 'BURDILÁKOVÁ',
            newbornRegistrationCount: 0,
            newRegistrationCount: 0,
            changedRegistrationCount: 79,
          },
        ],
      },
    },
  }),

  getListEmployee: (employeerInternalNumber, dateFrom, dateThru) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetListEmployee`),
      body: createBodyLog({
        employeerInternalNumber,
        dateFrom,
        dateThru,
      }),
      method: 'POST',
      actions: actionSets.GET_EMPLOYEE_LIST,
    },
  }),
  getPayouts: relationID => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetPayouts`),
      body: createBodyLog({ relationID }),
      method: 'POST',
      actions: actionSets.GET_PAYOUTS_LIST,
    },
  }),
  getEmployeeDocumentsList: () => ({
    [API_CALL]: {
      url: createUrl(`${controller}/get-employee-documents-list`),
      body: createBodyLog({}),
      method: 'POST',
      actions: actionSets.GET_EMPLOYEE_DOCUMENTS_LIST,
    },
  }),
  getEmployeeDocument: documentUuid => ({
    [API_CALL]: {
      url: createUrl(`${controller}/get-employee-document`),
      body: createBodyLog({ documentUuid }),
      method: 'POST',
      actions: actionSets.GET_EMPLOYEE_DOCUMENT,
      meta: { documentUuid },
    },
  }),
  getPayoutDocument: (docKey, id) => ({
    [API_CALL]: {
      url: createUrl(`${controller}/GetPayoutDocument`),
      body: createBodyLog({ docKey }),
      method: 'POST',
      actions: actionSets.GET_PAYOUT_DOCUMENT,
      meta: { payoutId: id },
    },
  }),
};

export default extranet;
