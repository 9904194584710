import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import '@dovera/design-system/dist/style.css';
import '@dovera/design-system/dist/polyfills';
import 'core-js/es';
import TagManager from 'react-gtm-module';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const tagManagerArgs = {
  gtmId: window.env.REACT_APP_GA_ID,
};

if (window.env.BUILD_VARIANT === 'production') {
  TagManager.initialize(tagManagerArgs);
}

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
