import {
  STORE_OCR_DATA,
  EMPTY_OCR_DATA,
  RESET_NFINITY_OCR_TRANSACTION,
  RESET_NFINITY_OCR,
} from '../actions/ocr-actions';
import { OcrReducer } from '../types/reducers.d';
import {
  GET_PERSON_DATA,
  NFINITY_OCR_CREATE_TRANSACTION,
  NFINITY_OCR_GET_DATA,
  OCR_CAPTURED_IMAGE,
} from '../api/actionSets';
import { RESET_REGISTRATION } from '../actions/registration-action';

const defaultState: OcrReducer = {
  step0: {
    isLoading: false,
    isLoaded: false,
    type: undefined,
    data: null,
  },
  step1: {
    isLoading: false,
    isLoaded: false,
    type: undefined,
    data: null,
  },
  contract: {
    isLoading: false,
    isLoaded: false,
    type: undefined,
    data: null,
  },
  nfinity_ocr: {
    transaction: {
      data: null,
      error: null,
      isLoading: false,
      isLoaded: false,
    },
    transactionData: {
      data: null,
      error: null,
      isLoading: false,
      isLoaded: false,
      is_error: false,
    },
  },
};

export default (state: OcrReducer = defaultState, action) => {
  switch (action.type) {
    case RESET_REGISTRATION:
      return defaultState;
    case OCR_CAPTURED_IMAGE.LOADING:
      return {
        ...state,
        [action.step]: {
          ...state[action.step],
          isLoading: true,
          isLoaded: false,
          isError: false,
          error: null,
        },
      };
    case OCR_CAPTURED_IMAGE.ERROR:
      return {
        ...state,
        [action.step]: {
          ...state[action.step],
          isLoading: false,
          isLoaded: false,
          isError: true,
          error: action.error,
        },
      };
    case STORE_OCR_DATA:
      return {
        ...state,
        [action.step]: {
          ...state[action.step],
          isLoading: false,
          isLoaded: true,
          type: action.insureeType,
          data: action.data,
        },
      };
    case EMPTY_OCR_DATA:
      return {
        ...state,
        [action.step]: {
          ...state[action.step],
          isLoading: false,
          isLoaded: true,
          type: action.insureeType,
          data: null,
        },
      };
    case NFINITY_OCR_CREATE_TRANSACTION.LOADING:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transaction: {
            ...state.nfinity_ocr.transaction,
            isLoading: true,
            isLoaded: false,
            error: null,
            data: null,
          },
        },
      };
    case NFINITY_OCR_CREATE_TRANSACTION.ERROR:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transaction: {
            ...state.nfinity_ocr.transaction,
            isLoading: false,
            isLoaded: true,
            is_error: true,
            error: action,
          },
        },
      };
    case NFINITY_OCR_CREATE_TRANSACTION.SUCCESS:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transaction: {
            ...state.nfinity_ocr.transaction,
            isLoading: false,
            isLoaded: true,
            error: null,
            data: {
              url: action.response.url,
              transactionGuid: action.response.transactionGuid,
            },
          },
        },
      };

    case RESET_NFINITY_OCR_TRANSACTION: {
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transaction: {
            ...state.nfinity_ocr.transaction,
            isLoading: false,
            isLoaded: false,
            error: null,
            data: null,
          },
        },
      };
    }

    case RESET_NFINITY_OCR: {
      return {
        ...state,
        nfinity_ocr: {
          transaction: {
            data: null,
            error: null,
            isLoading: false,
            isLoaded: false,
          },
          transactionData: {
            data: null,
            error: null,
            isLoading: false,
            isLoaded: false,
            is_error: false,
          },
        },
      };
    }

    case NFINITY_OCR_GET_DATA.LOADING:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transactionData: {
            ...state.nfinity_ocr.transactionData,
            isLoading: true,
            isLoaded: false,
            is_error: false,
            error: null,
            data: null,
          },
        },
      };
    case NFINITY_OCR_GET_DATA.ERROR:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transactionData: {
            ...state.nfinity_ocr.transactionData,
            isLoading: false,
            isLoaded: true,
            is_error: true,
            error: action,
          },
        },
      };
    case NFINITY_OCR_GET_DATA.SUCCESS:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transactionData: {
            ...state.nfinity_ocr.transactionData,
            isLoading: false,
            isLoaded: true,
            is_error: false,
            error: null,
            data: action.response,
          },
        },
      };
    case GET_PERSON_DATA.SUCCESS:
      return {
        ...state,
        nfinity_ocr: {
          ...state.nfinity_ocr,
          transactionData: {
            ...state.nfinity_ocr.transactionData,
            isLoading: false,
            isLoaded: true,
            is_error: false,
            error: null,
            data: {
              verificationDocument: {
                ...action.response?.registration?.identificationDocument,
                front:
                  action.response?.registration?.identificationDocument?.predna,
                back:
                  action.response?.registration?.identificationDocument?.zadna,
              },
            },
          },
        },
      };

    default:
      return state;
  }
};
