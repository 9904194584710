/* eslint-disable react/prefer-stateless-function */
import React, { Suspense, lazy } from 'react';
import { IconSpritePathProvider } from '@dovera/design-system';
import injectSheet, { ThemeProvider } from 'react-jss';
import { defaultStore } from './_common/store/configureStore';
import { Provider } from 'react-redux';
import styles from './styles';
import { AuthProvider } from './_common/providers/AuthProvider';
import { OcrProvider } from './_common/providers/OcrProvider';
import { initAuthState } from './_common/actions/auth-actions';
import { resetRegData } from './_common/actions/registration-action';
import SplashScreen from './components/splashScreen';
import {
  transitions,
  positions,
  types,
  Provider as AlertProvider,
} from 'react-alert';
import FloatingAlertTemplate from './components/FloatingAlert/FloatingAlertTemplate';
import api from './_common/api/api';
import { setCountries } from './_common/actions/codeLists-action';

const AppRouter = lazy(() => import('./routers/AppRouter'));

interface AppProps {
  classes: any;
}

declare global {
  interface Window {
    env: any;
  }
}

// temporary solution for removing annoying deprecation message. This should be solved with migrating react-jss to version 10
const filterJssWarning = message => {
  if (
    message &&
    typeof message === 'string' &&
    message.includes('has been renamed, and is not recommended for use') &&
    message.includes('Jss(')
  ) {
    return '';
  }
  return message;
};

// eslint-disable-next-line no-console
console.warn = filterJssWarning;

const theme = {};

// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 0,
  type: types.INFO,
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    marginTop: '4rem',
    zIndex: 201,
  },
};

function App() {
  //  @ts-ignore
  defaultStore.dispatch(api.log.getNewThread());
  //  @ts-ignore
  defaultStore.dispatch(api.version.getSystemStatus());
  defaultStore.dispatch(initAuthState());
  defaultStore.dispatch(resetRegData);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (window.env.BUILD_VARIANT !== 'dev')
        //  @ts-ignore
        defaultStore.dispatch(api.version.getSystemStatus());
    }, 60000);

    api.address.getAllCountries().then(countries => {
      defaultStore.dispatch(setCountries(countries));
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <IconSpritePathProvider
      value={{
        spritePath: `${window &&
          window.env &&
          window.env.config &&
          window.env.config.baseURI}/assets/sprite.svg`,
      }}
    >
      <AlertProvider
        // @ts-ignore
        template={FloatingAlertTemplate}
        {...options}
      >
        <Provider store={defaultStore}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <OcrProvider>
                <Suspense fallback={<SplashScreen />}>
                  <AppRouter />
                </Suspense>
              </OcrProvider>
            </AuthProvider>
          </ThemeProvider>
        </Provider>
      </AlertProvider>
    </IconSpritePathProvider>
  );
}

const StyledApp = injectSheet(styles)(App);
export default StyledApp;
